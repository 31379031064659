@font-face {
  font-family: "Pretendard";
  src: url("../src/assets/fonts/Pretendard-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard";
  src: url("../src/assets/fonts/Pretendard-Medium.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard";
  src: url("../src/assets/fonts/Pretendard-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard";
  src: url("../src/assets/fonts/Pretendard-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Gmarket Sans";
  src: url("../src/assets/fonts/GmarketSansTTFBold.ttf") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gmarket Sans";
  src: url("../src/assets/fonts/GmarketSansTTFMedium.ttf") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gmarket Sans";
  src: url("../src/assets/fonts/GmarketSansTTFLight.ttf") format("woff2");
  font-weight: 300;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  outline: 0;
  border: 0;
  text-decoration: none;
  background: none;
  font-family: Pretendard, Gmarket Sans sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
  background: #262626;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (max-width: 800px) {
    overflow: hidden;
  }
}

input {
  width: 100%;
  padding: 18px 16px;
  border-radius: 12px;
  border: 1px solid #e0e0ea;
  font-size: 16px;
  line-height: 20px;
}

input:focus-visible {
  outline: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

textarea {
  width: 100%;
  height: 280px;
  padding: 18px 16px;
  border-radius: 12px;
  border: 1px solid #e0e0ea;
  resize: vertical;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.005em;
}

textarea::placeholder,
input::placeholder {
  color: #bcbcc7;
}

/* button */
button {
  cursor: pointer;
}

/* media query */
@media (max-width: 576px) {
  select {
    padding: 12px 28px 12px 12px;
    background-size: 10px auto;
    background-position: calc(100% - 10px) center;
    font-size: 16px;
    line-height: 16px;
  }
}
@media (max-width: 414px) {
  select {
    background-size: 7px auto;
    background-position: calc(100% - 14px) center;
    font-size: 12px;
    line-height: 12px;
  }
  input,
  textarea {
    padding: 16px;
    font-size: 14px;
    line-height: 16px;
  }
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  transform: skew(-0.1deg);
}
