.about-first {
  max-width: 1920px;
  margin: 0 auto;
  height: 100vh;
  position: relative;
}

.about-first ul {
  display: flex;
  justify-content: space-between;
}

.about-first ul li {
  width: 50%;
}

.about-first ul .left {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
}

/*common*/
.about-first ul .left .txt h3 {
  padding-left: 0.26vw; /* 5px -> 0.26vw */
  font-size: 1.25vw; /* 18px -> 0.94vw */
  background: linear-gradient(90deg, #bcffe0, #c6c4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 300;
}
.about-first ul .left .txt h2 {
  font-weight: 700;
  font-size: 3vw; /* 50px -> 2.6vw */
  line-height: 3.125vw; /* 60px -> 3.125vw */
  letter-spacing: -0.104vw; /* -2px -> -0.104vw */
  padding: 0.94vw 0 1.3vw 0; /* 18px 0 25px 0 -> 0.94vw 0 1.3vw 0 */
}
.about-first ul .left .txt h2 span {
  font-weight: 200;
}
.about-first ul .left .txt p {
  font-weight: 200;
  line-height: 150%;
  color: #d9d9d9;
  opacity: 0.8;
  font-size: 1.2vw; /* 20px -> 1.04vw */
  letter-spacing: -0.021vw; /* -0.4px -> -0.021vw */
}
.about-first ul .left .place {
  padding-top: 5.21vw; /* 100px -> 5.21vw */
}
.about-first ul .left .place span {
  color: #9d9d9d;
  background: #1a1a1a;
  display: inline-block;
  text-align: center;
  font-size: 1.34vw; /* 20px -> 1.04vw */
  padding: 1.3vw 1.56vw; /* 25px 30px -> 1.3vw 1.56vw */
  border-radius: 0.78vw; /* 15px -> 0.78vw */
  margin-right: 0.78vw; /* 15px -> 0.78vw */
  width: 10.33vw; /* 160px -> 8.33vw */
}

.about-first ul .left .place span:first-child {
  color: #94fecf;
  border: 1px solid #94fecf;
}

.about-first ul .right {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
}
.about-first ul .right .map {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: -3;
}
/*.about-first ul .right .map > .img > img { background: radial-gradient(circle, rgba(0, 0, 0, 100) 0%, rgba(0, 0, 0, 1) 0%);}*/
.about-first ul .right .icon {
  position: absolute;
  z-index: 1;
  width: 100%;
}

/*video*/
.about-first ul .right .icon .video-container1 {
  position: absolute;
  bottom: -2vw;
  right: 11.5vw;
  width: 16.04vw; /* 308px -> 16.04vw */
  height: 17.19vw; /* 330px -> 17.19vw */
}
.video-container1 > .img {
  position: absolute;
  top: 43%;
  left: 42%;
  transform: translate(-50%, -50%);
  width: 16.04vw; /* 308px -> 16.04vw */
  height: 17.19vw; /* 330px -> 17.19vw */
  z-index: 1; /* SVG를 비디오 위에 올리기 위한 z-index 추가 */
}
.video-container1 > span {
  position: absolute;
  top: 40%;
  left: 42%;
  width: 13.8vw; /* 265px -> 13.80vw */
  height: 13.8vw; /* 265px -> 13.80vw */
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}
.video-container1 > span > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-container2 {
  position: absolute;
  left: 4vw;
  top: -4vw;
  border: 1px solid transparent;
  background-image: linear-gradient(#000, #000),
    linear-gradient(90deg, #bcffe0, #c6c4ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 9.79vw; /* 188px -> 9.79vw */
  height: 9.79vw; /* 188px -> 9.79vw */
  border-radius: 10.42vw; /* 200px -> 10.42vw */
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-container2 > span {
  width: 8.59vw; /* 165px -> 8.59vw */
  height: 8.59vw; /* 165px -> 8.59vw */
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  position: relative;
}
.video-container2 > span > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-container3 {
  position: absolute;
  left: 16vw;
  top: 6vw;
  border: 1px solid transparent;
  border-radius: 50%;
  background-image: linear-gradient(#000, #000),
    linear-gradient(90deg, #bcffe0, #c6c4ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  height: 6.875vw; /* 132px -> 6.875vw */
  width: 6.875vw; /* 132px -> 6.875vw */
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-container3 > span {
  width: 5.73vw; /* 110px -> 5.73vw */
  height: 5.73vw; /* 110px -> 5.73vw */
  border-radius: 50%; /* 내부 span도 원형 */
  overflow: hidden; /* 비디오가 넘치지 않도록 */
  display: inline-block;
  position: relative;
}

.video-container3 > span > video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 비디오가 원 안에 꽉 차도록 */
}

.about-first ul .right .icon .video-container4 .img:first-child {
  position: absolute;
  top: -10.5vw;
  left: 8vw;
  width: 4.48vw; /* 86px -> 4.48vw */
  height: 4.48vw; /* 86px -> 4.48vw */
}

.about-first ul .right .icon .video-container4 .img:last-child {
  position: absolute;
  top: 1vw;
  right: 10.5vw;
  width: 3.39vw; /* 65px -> 3.39vw */
  height: 3.39vw; /* 65px -> 3.39vw */
}

/*dots*/
.about-second ul .right .dots {
  width: 100%;
  position: absolute;
}

.glow-circle {
  position: relative;
  width: 18px;
  height: 18px;
  background-color: #73f2bc;
  border-radius: 50%;
  animation: glow 3s infinite ease-in-out;
}

.glow-circle::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  background-color: rgba(115, 242, 188, 0.3);
  border-radius: 50%;
  z-index: -1;
}

.glow-circle:nth-child(1) {
  position: absolute;
  right: 20vw;
  top: 1vw;
}

.glow-circle:nth-child(2) {
  position: absolute;
  right: 12vw;
  top: 4.3vw;
  opacity: 0.6;
  width: 10px;
  height: 10px;
}
.glow-circle:nth-child(2)::before {
  width: 20px;
  height: 20px;
}

.glow-circle:nth-child(3) {
  position: absolute;
  left: 10.3vw;
  top: -6vw;
  opacity: 0.3;
  width: 10px;
  height: 10px;
}
.glow-circle:nth-child(3)::before {
  width: 20px;
  height: 20px;
}

.glow-circle:nth-child(4) {
  position: absolute;
  left: 9vw;
  top: 6.5vw;
  opacity: 0.3;
  width: 15px;
  height: 15px;
}
.glow-circle:nth-child(4)::before {
  width: 25px;
  height: 25px;
}

.glow-circle:nth-child(5) {
  position: absolute;
  left: 19.5vw;
  top: 14vw;
  opacity: 0.6;
  width: 10px;
  height: 10px;
}
.glow-circle:nth-child(5)::before {
  width: 20px;
  height: 20px;
}

.glow-circle:nth-child(6) {
  position: absolute;
  right: 20vw;
  top: 4vw;
  opacity: 0.6;
  width: 10px;
  height: 10px;
}
.glow-circle:nth-child(6)::before {
  width: 20px;
  height: 20px;
}

.glow-circle:nth-child(7) {
  position: absolute;
  left: 20vw;
  top: 1.5vw;
  opacity: 0.3;
  width: 10px;
  height: 10px;
}
.glow-circle:nth-child(7)::before {
  width: 20px;
  height: 20px;
}

/*.about-first ul .right .dot {position:absolute;}*/
/*.about-first ul .right .dot span {position: absolute;content: '';width:18px;height: 18px;color: #94FECF;z-index: 100;}*/

/* 1920px - 1280px 반응형 */
@media screen and (max-width: 1920px) {
  .video-container1 {
    top: calc(-326px + (-17vw - -326px) * ((1920px - 100vw) / 640));
    left: calc(300px + (15.6vw - 300px) * ((1920px - 100vw) / 640));
  }

  .video-container2 {
    left: calc(58px + (3vw - 58px) * ((1920px - 100vw) / 640));
    top: calc(-58px + (-3vw - -58px) * ((1920px - 100vw) / 640));
  }

  .video-container3 {
    left: calc(269px + (14vw - 269px) * ((1920px - 100vw) / 640));
    top: calc(134px + (7vw - 134px) * ((1920px - 100vw) / 640));
  }
}

/* 1280px 이하 적응형 */
@media screen and (max-width: 1280px) {
  .about-first {
    width: 1080px;
    min-width: 1080px;
    overflow: hidden; /* 추가 */
  }

  .about-first ul {
    width: 1080px;
  }

  .about-first ul li {
    width: 640px; /* 50% of 1280px */
  }

  .about-first ul .left {
    position: absolute;
    top: 50%;
    left: 384px; /* 30% of 1280px */
    transform: translate(-50%, -50%);
  }

  .about-first ul .left .txt h3 {
    padding-left: 3px;
    font-size: 18px;
  }

  .about-first ul .left .txt h2 {
    font-size: 46px;
    line-height: 40px;
    letter-spacing: -1px;
    padding: 12px 0 17px 0;
  }

  .about-first ul .left .txt p {
    font-size: 20px;
    letter-spacing: -0.3px;
  }

  .about-first ul .left .place {
    padding-top: 67px;
  }

  .about-first ul .left .place span {
    font-size: 17px;
    padding: 17px 20px;
    border-radius: 10px;
    margin-right: 10px;
    width: 132px;
  }

  .about-first ul .right {
    position: absolute;
    top: 55%;
    left: 560px;
    transform: translateY(-50%);
  }

  /* 비디오 컨테이너 위치와 크기 조정 */
  .about-first ul .right .icon .video-container1 {
    bottom: -26px;
    right: 147px;
    width: 205px;
    height: 220px;
  }

  .video-container1 > .img {
    width: 205px;
    height: 220px;
  }

  .video-container1 > span {
    width: 177px;
    height: 177px;
  }

  .video-container2 {
    left: 51px;
    top: -51px;
    width: 125px;
    height: 125px;
    border-radius: 133px;
  }

  .video-container2 > span {
    width: 110px;
    height: 110px;
  }

  .video-container3 {
    left: 205px;
    top: 77px;
    height: 88px;
    width: 88px;
  }

  .video-container3 > span {
    width: 73px;
    height: 73px;
  }

  .about-first ul .right .icon .video-container4 .img:first-child {
    top: -144px;
    left: 126px;
    width: 57px;
    height: 57px;
  }

  .about-first ul .right .icon .video-container4 .img:last-child {
    top: 13px;
    right: 134px;
    width: 43px;
    height: 43px;
  }

  /* dots 위치 고정 */
  .glow-circle:nth-child(1) {
    right: 256px;
    top: 13px;
  }
  .glow-circle:nth-child(2) {
    right: 154px;
    top: 55px;
  }
  .glow-circle:nth-child(3) {
    left: 148px;
    top: -85px;
  }
  .glow-circle:nth-child(4) {
    left: 115px;
    top: 83px;
  }
  .glow-circle:nth-child(5) {
    left: 245px;
    top: 179px;
  }
  .glow-circle:nth-child(6) {
    right: 256px;
    top: 66px;
  }
  .glow-circle:nth-child(7) {
    left: 290px;
    top: 16px;
  }
}

/* 480px 이하 모바일 레이아웃 */
@media screen and (max-width: 480px) {
  .about-first {
    width: 100%;
    min-width: auto;
  }

  .about-first ul {
    width: 100%;
    flex-direction: column;
  }

  .about-first ul li {
    width: 100%;
  }

  /* 왼쪽 텍스트 영역 조정 */
  .about-first ul .left {
    position: relative;
    top: 12vw; /* 120px -> 25vw */
    left: 0;
    transform: none;
  }

  .about-first ul .left .txt h3 {
    font-size: 4.7vw; /* 16px -> 3.33vw */
    padding-bottom: 2.8vw;
  }

  .about-first ul .left .txt h2 {
    font-size: 7.5vw; /* 24px -> 5vw */
    line-height: 6.67vw; /* 32px -> 6.67vw */
    letter-spacing: -0.1vw; /* -0.5px -> -0.1vw */
    padding-top: 1.6vw;
  }

  .about-first ul .left .txt p {
    font-size: 4.3vw; /* 16px -> 3.33vw */
    line-height: 5vw; /* 24px -> 5vw */
    letter-spacing: -0.06vw; /* -0.3px -> -0.06vw */
  }

  .about-first ul .left .place {
    padding-top: 12vw;
    display: flex;
    gap: 2vw; /* 8px -> 1.67vw */
  }

  .about-first ul .left .place span {
    font-size: 4.2vw; /* 14px -> 2.92vw */
    padding: 2.5vw 3.33vw; /* 12px 16px -> 2.5vw 3.33vw */
    border-radius: 2vw;
    margin-right: 0;
    height: 14.83vw; /* 52px -> 10.83vw */
    display: flex;
    align-items: center;
    justify-content: center; /* 텍스트 가운데 정렬 */
  }

  /* 오른쪽 지도 영역 조정 */
  .about-first ul .right {
    position: relative;
    top: 4.17vw; /* 140px -> 29.17vw */
    left: 0;
    transform: none;
    height: 83.33vw; /* 400px -> 83.33vw */
  }

  .about-first ul .right .map {
    position: relative;
    top: 70%;
    transform: translateY(-50%);
    z-index: 0;
  }

  .about-first ul .right .map img {
    width: 100%;
    height: auto;
  }

  /* 비디오 컨테이너 위치 및 크기 조정 */
  .about-first ul .right .icon .video-container1 {
    bottom: 18.33vw; /* 40px -> 8.33vw */
    right: 4.17vw; /* 20px -> 4.17vw */
    width: 35vw; /* 120px -> 25vw */
    height: 37.08vw; /* 130px -> 27.08vw */
  }

  .video-container1 > .img {
    width: 35vw;
    height: 37.08vw;
  }

  .video-container1 > span {
    width: 29.33vw; /* 100px -> 20.83vw */
    height: 29.33vw;
  }

  .video-container2 {
    left: 0vw; /* 20px -> 4.17vw */
    top: -28vw; /* 60px -> 12.5vw */
    width: 23.71vw; /* 85px -> 17.71vw */
    height: 23.71vw;
    border-radius: 17.71vw;
  }

  .video-container2 > span {
    width: 20.63vw; /* 75px -> 15.63vw */
    height: 20.63vw;
  }

  .video-container3 {
    left: 30vw; /* 120px -> 25vw */
    top: -9.17vw; /* 140px -> 29.17vw */
    height: 13.54vw; /* 65px -> 13.54vw */
    width: 13.54vw;
  }

  .video-container3 > span {
    width: 11.46vw; /* 55px -> 11.46vw */
    height: 11.46vw;
  }

  /* 작은 아이콘 위치 조정 */
  .about-first ul .right .icon .video-container4 .img:first-child {
    top: -47.17vw; /* 20px -> 4.17vw */
    left: 11.83vw; /* 100px -> 20.83vw */
    width: 14.33vw; /* 40px -> 8.33vw */
    height: 14.33vw;
  }

  .about-first ul .right .icon .video-container4 .img:last-child {
    top: -20vw; /* 120px -> 25vw */
    right: 0vw; /* 40px -> 8.33vw */
    width: 14.25vw; /* 30px -> 6.25vw */
    height: 14.25vw;
  }

  /* dots 위치 조정 */
  .glow-circle:nth-child(1) {
    right: 18vw; /* 80px -> 16.67vw */
    top: 65.25vw; /* 30px -> 6.25vw */
  }

  .glow-circle:nth-child(2) {
    right: 18vw; /* 40px -> 8.33vw */
    top: 75.25vw; /* 80px -> 16.67vw */
  }

  .glow-circle:nth-child(3) {
    left: 58vw; /* 60px -> 12.5vw */
    top: 65.17vw; /* 20px -> 4.17vw */
  }

  .glow-circle:nth-child(4) {
    left: 21.17vw; /* 140px -> 29.17vw */
    top: 80.67vw; /* 200px -> 41.67vw */
  }

  .glow-circle:nth-child(5) {
    left: 43.33vw; /* 40px -> 8.33vw */
    top: 90.33vw; /* 160px -> 33.33vw */
  }

  .glow-circle:nth-child(6) {
    right: 0vw; /* 100px -> 20.83vw */
    top: 76.17vw; /* 140px -> 29.17vw */
  }

  .glow-circle:nth-child(7) {
    left: 25.33vw; /* 160px -> 33.33vw */
    top: 50.33vw; /* 40px -> 8.33vw */
  }

  /* glow-circle 크기 조정 */
  .glow-circle {
    width: 2.5vw; /* 12px -> 2.5vw */
    height: 2.5vw;
  }

  .glow-circle::before {
    width: 4.17vw; /* 20px -> 4.17vw */
    height: 4.17vw;
  }

  .glow-circle:nth-child(2),
  .glow-circle:nth-child(3),
  .glow-circle:nth-child(5),
  .glow-circle:nth-child(6),
  .glow-circle:nth-child(7) {
    width: 1.67vw; /* 8px -> 1.67vw */
    height: 1.67vw;
  }

  .glow-circle:nth-child(2)::before,
  .glow-circle:nth-child(3)::before,
  .glow-circle:nth-child(5)::before,
  .glow-circle:nth-child(6)::before,
  .glow-circle:nth-child(7)::before {
    width: 3.33vw; /* 16px -> 3.33vw */
    height: 3.33vw;
  }
}
