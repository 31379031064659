.about-fifth {
  max-width: 1920px;
  margin: 0 auto; /* auto를 추가하여 수평 중앙 정렬 */
  min-height: 100vh; /* 최소 높이 설정 */
  position: relative; /* 추가 */
  display: flex; /* Flexbox 추가 */
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
}

.about-fifth .inner {
  height: 100vh;
  position: relative;
  width: 100%; /* 너비 지정 */
  margin-top: 5vw;
  margin-left: 6vw;
}

/* SVG 아이콘 기본 스타일 */
.about-fifth .inner .icon {
  position: relative;
  width: clamp(93px, 7.81vw, 150px);
  height: clamp(93px, 7.81vw, 150px);
  z-index: 0;
}

/* 첫 번째 아이콘 (왼쪽 아래) */
.about-fifth .inner .icon1 {
  left: 0vw;
  bottom: 11vw;
}

/* 두 번째 아이콘 (가운데) */
.about-fifth .inner .icon2 {
  left: 9vw;
  bottom: 8vw;
}

/* 세 번째 아이콘 (오른쪽) */
.about-fifth .inner .icon3 {
  left: 46vw;
  bottom: 15vw;
}

/* 아이콘 이미지 스타일링 */
.about-fifth .inner .icon img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.about-fifth .top {
  display: block;
  width: 100%;
  margin-bottom: 22vw;
}
/*common*/
.about-fifth .top h3 {
  padding-left: 0.26vw; /* 5px -> 0.26vw */
  font-size: 1.25vw; /* 18px -> 0.94vw */
  background: linear-gradient(90deg, #bcffe0, #c6c4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 300;
}
.about-fifth .top h2 {
  font-weight: 700;
  font-size: 3vw; /* 50px -> 2.60vw */
  line-height: 3.825vw; /* 60px -> 3.125vw */
  letter-spacing: -0.052vw; /* -1px -> 0.052vw */
  padding: 0.94vw 0 1.3vw 0; /* 18px 0 25px 0 -> 0.94vw 0 1.30vw 0 */
}
.about-fifth .top h2 span {
  font-weight: 200;
}

.about-fifth .bottom {
  position: relative;
  width: 100%;
}
.about-fifth .bottom p {
  display: inline-block;
  background: #ffffff;
  position: absolute;
  padding: 1.25vw 2.81vw; /* 24px 54px -> 1.25vw 2.81vw */
  font-size: 1.2vw; /* 37px -> 1.93vw */
  border-radius: 2.6vw; /* 50px -> 2.60vw */
}

.about-fifth .bottom p:nth-child(1),
.about-fifth .bottom p:nth-child(3) {
  color: #000;
  background: #94fecf;
}
.about-fifth .bottom p:nth-child(2),
.about-fifth .bottom p:nth-child(7) {
  background: #000;
  border: 0.104vw solid #fff; /* 2px -> 0.104vw */
}
.about-fifth .bottom p:nth-child(4),
.about-fifth .bottom p:nth-child(6) {
  color: #000;
}
.about-fifth .bottom p:nth-child(5) {
  background: #262626;
}

.about-fifth .bottom p:nth-child(1) {
  position: absolute;
  transform: rotate(-35deg);
  left: -1vw;
  top: -3vw;
  z-index: 3;
  width: 16.06vw;
  height: 5.05vw;
  font-size: 1.8vw; /* 37px -> 1.93vw */
}
.about-fifth .bottom p:nth-child(2) {
  position: absolute;
  transform: rotate(55deg);
  left: 13.5vw;
  top: -1vw;
  z-index: 2;
  width: 11.71vw;
  height: 5vw;
  font-size: 1.55vw; /* 37px -> 1.93vw */
}
.about-fifth .bottom p:nth-child(3) {
  position: absolute;
  transform: rotate(-15deg);
  left: 20.5vw;
  top: -4.5vw;
  z-index: 2;
  width: 11.21vw;
  height: 5vw;
  font-size: 1.6vw; /* 37px -> 1.93vw */
}
.about-fifth .bottom p:nth-child(4) {
  position: absolute;
  transform: rotate(10deg);
  left: 26.5vw;
  top: 0.5vw;
  z-index: 23;
  width: 17vw;
  height: 5vw;
  font-size: 1.8vw; /* 37px -> 1.93vw */
}
.about-fifth .bottom p:nth-child(5) {
  position: absolute;
  transform: rotate(31deg);
  left: 30vw;
  top: -4.5vw;
  z-index: 1;
  width: 16vw;
  height: 5vw;
  font-size: 1.8vw; /* 37px -> 1.93vw */
}
.about-fifth .bottom p:nth-child(6) {
  position: absolute;
  transform: rotate(17deg);
  left: 53vw;
  top: 1vw;
  z-index: 0;
  width: 18vw;
  height: 5vw;
  font-size: 1.8vw; /* 37px -> 1.93vw */
}
.about-fifth .bottom p:nth-child(7) {
  position: absolute;
  transform: rotate(-8deg);
  left: 50vw;
  top: -5.5vw;
  z-index: 0;
  width: 18vw;
  height: 5vw;
  font-size: 1.8vw; /* 37px -> 1.93vw */
}

/* SVG 아이콘을 위한 특별한 클래스 추가 */
.about-fifth .bottom .icon {
  border: 2px solid #fff;
  position: absolute;
  z-index: 3; /* 텍스트와의 레이어 관계 조정 */
  background-color: transparent; /* 배경 투명하게 */
}

/* 1280px - 360px 적응형 */
@media screen and (max-width: 1280px) {
  .about-fifth {
    width: 1080px;
    min-width: 1080px;
  }

  .about-fifth .inner {
    width: 1080px;
    margin-top: 10px;
  }

  /* 아이콘 위치 */
  .about-fifth .inner .icon1 {
    left: 0;
    bottom: 211px; /* 11vw */
  }

  .about-fifth .inner .icon2 {
    left: 130px; /* 9vw */
    bottom: 210px; /* 8vw */
  }

  .about-fifth .inner .icon3 {
    left: 603px; /* 46vw */
    bottom: 308px; /* 15vw */
  }

  .about-fifth .top {
    display: block;
    margin-bottom: 380px; /* 22vw */
  }

  /* 공통 스타일 */
  .about-fifth .top h3 {
    padding-left: 5px; /* 0.26vw */
    font-size: 18px; /* 0.94vw */
  }

  .about-fifth .top h2 {
    font-size: 50px; /* 2.6vw */
    line-height: 60px; /* 3.13vw */
    letter-spacing: -1px; /* -0.052vw */
    padding: 18px 0 25px 0; /* 0.94vw 0 1.3vw 0 */
  }

  .about-fifth .bottom p {
    padding: 15px 48px;
    border-radius: 50px; /* 2.6vw */
    text-align: center;
  }

  /* 텍스트 박스 위치들 */
  .about-fifth .bottom p:nth-child(1) {
    left: 0px; /* -1vw */
    top: -135px; /* -3vw */
    width: 232px; /* 16.06vw */
    height: 60px; /* 5.05vw */
    font-size: 24px; /* 1.8vw */
    padding: 15px 48px;
  }

  .about-fifth .bottom p:nth-child(2) {
    left: 185px; /* 13.5vw */
    top: -110px; /* -1vw */
    width: 158px; /* 11.71vw */
    height: 60px; /* 5.05vw */
    font-size: 24px; /* 1.55vw */
    border: 2px solid #fff; /* 0.104vw */
    padding: 15px 35px;
  }

  .about-fifth .bottom p:nth-child(3) {
    left: 274px; /* 20.5vw */
    top: -150px; /* -4.5vw */
    width: 162px; /* 11.21vw */
    height: 58px; /* 5vw */
    font-size: 24px; /* 1.6vw */
    padding: 15px 35px;
  }

  .about-fifth .bottom p:nth-child(4) {
    left: 360px; /* 26.5vw */
    top: -90px; /* 0.5vw */
    width: 215px; /* 11.21vw */
    height: 60px; /* 5vw */
    font-size: 24px; /* 1.8vw */
    padding: 15px 35px;
  }

  .about-fifth .bottom p:nth-child(5) {
    left: 390px; /* 30vw */
    top: -160px; /* -4.5vw */
    width: 215px; /* 11.21vw */
    height: 60px; /* 5vw */
    font-size: 24px; /* 1.8vw */
    padding: 15px 35px;
  }

  .about-fifth .bottom p:nth-child(6) {
    left: 685px; /* 53vw */
    top: -90px; /* 1vw */
    width: 230px; /* 11.21vw */
    height: 60px; /* 5vw */
    font-size: 24px; /* 1.8vw */
    padding: 15px 30px;
  }

  .about-fifth .bottom p:nth-child(7) {
    left: 655px; /* 53vw */
    top: -160px; /* 1vw */
    font-size: 24px; /* 1.8vw */
    width: 230px; /* 11.21vw */
    height: 60px; /* 5vw */
    border: 2px solid #fff; /* 0.104vw */
    padding: 15px 30px;
  }
}

@media screen and (max-width: 480px) {
  .about-fifth {
    width: 100%;
    min-width: auto;
    height: 100vh;
    margin-top: 0; /* 마진 제거 */
    display: flex;
    align-items: center;
    overflow: hidden; /* 오버플로우 방지 */
  }

  .about-fifth .inner {
    width: 100%;
    padding: 20.833vw 4.17vw 0; /* top, left/right, bottom */
    margin-left: -1vw;
  }

  .about-fifth .top {
    display: none;
  }

  .about-fifth .top2 {
    margin-bottom: 16.67vw;
  }

  .about-fifth .top2 h3 {
    font-size: 4.7vw;
    padding-left: 0;
    background: linear-gradient(90deg, #bcffe0, #c6c4ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 300;
    text-align: center;
  }

  .about-fifth .top2 h2 {
    font-size: 7.5vw;
    font-weight: 700;
    text-align: center;
    padding: 4.08vw 0;
  }

  .about-fifth .bottom {
    display: none;
  }

  .about-fifth .bottom2 {
    position: relative;
    width: 100%;
  }

  .about-fifth .bottom2 p {
    display: inline-block;
    background: #ffffff;
    font-size: 4.167vw;
    border-radius: 6.25vw;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  .about-fifth .bottom2 p:nth-child(1) {
    width: 28.333vw;
    height: 12.083vw;
    left: 0%;
    transform: translateX(-50);
    top: 14.333vw; /* 위치 조정 */
    transform: rotate(22deg);
  }

  .about-fifth .bottom2 p:nth-child(2) {
    width: 41.67vw;
    height: 12.083vw;
    left: 42%;
    top: 23.833vw;
    transform: translateX(-50);
    transform: rotate(-3.43deg);
    z-index: 2;
  }

  .about-fifth .bottom2 p:nth-child(3) {
    width: 41.67vw;
    height: 12.083vw;
    top: 37.833vw;
    left: 0%;
    transform: translateX(-50);
    transform: rotate(-20.9deg);
    z-index: 1;
  }

  .about-fifth .bottom2 p:nth-child(4) {
    width: 48.333vw;
    height: 12.083vw;
    top: 38.833vw;
    left: 40%;
    transform: translateX(-50);
    transform: rotate(-7.52deg);
    z-index: 0;
  }

  .about-fifth .bottom2 p:nth-child(5) {
    width: 48.333vw;
    height: 12.083vw;
    top: 61.833vw;
    left: 48%;
    transform: translateX(-50);
    transform: rotate(19.11deg);
    z-index: 1;
  }

  .about-fifth .bottom2 p:nth-child(6) {
    width: 48.333vw;
    height: 12.083vw;
    top: 60vw;
    left: 10%;
    transform: translateX(-50);
    transform: rotate(23deg);
  }

  .about-fifth .bottom2 p:nth-child(1),
  .about-fifth .bottom2 p:nth-child(5) {
    color: #000;
    background: #94fecf;
  }
  .about-fifth .bottom2 p:nth-child(4),
  .about-fifth .bottom2 p:nth-child(6) {
    background: #000;
    border: 0.104vw solid #fff; /* 2px -> 0.104vw */
  }
  .about-fifth .bottom2 p:nth-child(3) {
    background: #fff;
    color: #000;
  }
  .about-fifth .bottom2 p:nth-child(2) {
    background: #262626;
  }

  /* 아이콘 크기 및 위치 조정 */
  .about-fifth .inner .icon {
    width: 16vw;
    height: 16vw;
  }

  .about-fifth .inner .icon1 {
    left: 85%;
    transform: translateX(-50);
    top: 45vw;
  }

  .about-fifth .inner .icon2 {
    left: 33%;
    transform: translateX(-50);
    top: -6.5vw;
  }

  .about-fifth .inner .icon3 {
    left: 0%;
    transform: translateX(-50);
    top: -7vw;
  }
}

/* 480px 초과 시에는 숨김 */
@media screen and (min-width: 481px) {
  .about-fifth .top2 {
    display: none;
  }

  .about-fifth .bottom2 {
    display: none;
  }
}
