footer {
  margin: 0 auto;
}

footer .wrapper {
  padding-top: 5.208vw; /* 100px -> 5.208vw */
  height: 100vh;
  position: relative;
}
footer .wrapper > span {
  position: absolute;
  right: 9.5vw;
  z-index: 2;
  top: -2vw;
}
footer .wrapper > span img {
  width: 21.658vw;
  height: 35.5vw;
}

footer div span img {
  display: block;
}

/* 상단 그라데이션 박스 */
footer .top {
  display: block;
}

footer .top {
  width: 100%;
  height: 29.375vw; /* 564px -> 29.375vw */
  border-radius: 3.125vw; /* 60px -> 3.125vw */
  background: linear-gradient(90deg, #bcffe0 0%, #c6c4ff 100%);
  color: #262626;
  padding: 4.688vw 11.458vw; /* 90px 220px -> 4.688vw 11.458vw */
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 3.125vw; /* 60px -> 3.125vw */
}

footer .top .left h2 {
  padding-bottom: 2.031vw; /* 39px -> 2.031vw */
  font-size: 2.604vw; /* 50px -> 2.604vw */
  line-height: 130%;
}
footer .top .left h2 > span {
  font-weight: 200;
}
footer .top .left p {
  line-height: 1.2;
  font-size: 1.146vw; /* 22px -> 1.146vw */
  font-weight: 300;
  padding-bottom: 0.729vw; /* 14px -> 0.729vw */
}
footer .top .left p:last-child {
  padding-bottom: 3.125vw; /* 60px -> 3.125vw */
}
footer .top .left .paragraph p > span {
  padding-right: 0.833vw; /* 16px -> 0.833vw */
}
footer .top .btn a {
  border-radius: 2.083vw;
  padding: 1.042vw 1.354vw;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0.104vw 0.104vw 1.146vw rgba(109, 109, 109, 0.1);
  margin-right: 1.302vw;
  font-size: 0.99vw;
  font-weight: 300;
}

footer ul li .btn a span {
  vertical-align: middle;
}
footer ul li .btn a:first-of-type {
  margin-right: 15px;
}
footer ul li .btn a svg {
  margin-right: 0.6vw;
}

footer .top .right {
  width: 50%;
  position: relative;
}
footer .top .right span {
  position: absolute;
}
footer .top .right span:nth-child(1) {
  position: absolute;
  right: 6vw;
  top: 2vw;
  width: 30.5vw;
  height: 26.469vw;
}
footer .top .right span:nth-child(2) {
  position: absolute;
  right: -10vw;
  top: -3vw;
  mix-blend-mode: color-burn;
  opacity: 0.5;
  width: 32.031vw;
  height: 29.531vw;
}

.bottom-container {
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottom-left {
  display: flex;
  align-items: center;
  gap: 16px;
}
.logo-link {
  display: flex;
  align-items: center;
}

.logo {
  width: 7.448vw;
  height: 1.875vw;
}

.social-links {
  display: flex;
  gap: 0.156vw;
  padding-left: 1.823vw;
}

.social-link {
  display: flex;
  align-items: center;
}

.social-icon {
  width: 2.292vw;
  height: 2.292vw;
  transition: opacity 0.2s ease;
}

.social-link:hover .social-icon {
  opacity: 0.7;
}

.bottom-nav {
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
  gap: 24px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li a {
  color: #aaa;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.2s ease;
}

.nav-links li a:hover {
  color: #fff;
}

footer .info {
  color: #7b7b7b;
  font-size: 0.729vw; /* 14px */
  font-weight: 200;
  line-height: 0.885vw; /* 17px */
}

footer .info h3 {
  padding-top: 1.563vw; /* 30px */
}

footer .info .details {
  display: flex;
  padding: 0.833vw 0 1.563vw; /* 16px 0 30px */
}

footer .info .details p {
  padding-right: 1.563vw; /* 30px */
}

footer .info .details a {
  text-decoration-line: underline;
}

/* footer 버튼 스타일 수정 */
footer .top .btn a {
  border-radius: 2.083vw;
  padding: 1.042vw 1.354vw;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0.104vw 0.104vw 1.146vw rgba(109, 109, 109, 0.1);
  margin-right: 1.302vw;
  font-size: 0.99vw;
  font-weight: 300;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease;
  color: #1a1a1a; /* 기본 텍스트 색상 */
}

footer .top .btn a svg {
  width: 1.5vw;
  height: 1.5vw;
}

/* 호버 효과 */
footer .top .btn a:hover {
  background: #ffffff;
}

/* SVG 색상 변경 효과 */
footer .top .btn a svg path {
  transition: fill 0.3s ease;
}

@media screen and (max-width: 1280px) {
  footer .wrapper {
    padding-top: 67px; /* 5.208vw */
  }

  footer .wrapper > span {
    right: 122px; /* 9.5vw */
    top: -26px; /* -2vw */
  }

  footer .wrapper > span img {
    width: 277px; /* 21.658vw */
    height: 454px; /* 35.5vw */
  }

  footer .top {
    height: 376px; /* 29.375vw */
    border-radius: 40px; /* 3.125vw */
    padding: 60px 146px; /* 4.688vw 11.458vw */
    margin-bottom: 40px; /* 3.125vw */
  }

  footer .top .left h2 {
    padding-bottom: 26px; /* 2.031vw */
    font-size: 33px; /* 2.604vw */
  }

  footer .top .left p {
    font-size: 15px; /* 1.146vw */
    padding-bottom: 9px; /* 0.729vw */
  }

  footer .top .left p:last-child {
    padding-bottom: 40px; /* 3.125vw */
  }

  footer .top .left .paragraph p > span {
    padding-right: 11px; /* 0.833vw */
  }

  footer .top .btn a {
    border-radius: 27px; /* 2.083vw */
    padding: 13px 17px; /* 1.042vw 1.354vw */
    box-shadow: 1px 1px 15px rgba(109, 109, 109, 0.1); /* 0.104vw 0.104vw 1.146vw */
    margin-right: 17px; /* 1.302vw */
    font-size: 13px; /* 0.99vw */
  }

  footer ul li .btn a svg {
    width: 24px;
    height: 24px;
    margin-right: 8px; /* 0.6vw */
  }

  footer .top .right span:nth-child(1) {
    right: 77px; /* 6vw */
    top: 26px; /* 2vw */
    width: 390px; /* 30.5vw */
    height: 339px; /* 26.469vw */
  }

  footer .top .right span:nth-child(2) {
    right: -128px; /* -10vw */
    top: -38px; /* -3vw */
    width: 410px; /* 32.031vw */
    height: 378px; /* 29.531vw */
  }

  .logo {
    width: 95px; /* 7.448vw */
    height: 24px; /* 1.875vw */
  }

  .social-links {
    gap: 2px; /* 0.156vw */
    padding-left: 23px; /* 1.823vw */
  }

  .social-icon {
    width: 29px; /* 2.292vw */
    height: 29px; /* 2.292vw */
  }

  footer .info {
    font-size: 9px; /* 0.729vw */
    line-height: 11px; /* 0.885vw */
  }

  footer .info h3 {
    padding-top: 20px; /* 1.563vw */
  }

  footer .info .details {
    padding: 11px 0 20px; /* 0.833vw 0 1.563vw */
  }

  footer .info .details p {
    padding-right: 20px; /* 1.563vw */
  }
}

@media screen and (max-width: 480px) {
  footer {
    width: 100%;
    margin-top: 20vw;
  }

  footer .wrapper {
    height: auto;
  }

  footer div span img {
    display: none;
  }

  footer .top {
    width: 100%; /* 40px 여백 */
    height: auto;
    margin: 0;
    padding: 8.33vw 8.375vw;
    position: relative;
    flex-direction: column;
    align-items: center;
    gap: 3.75vw;
    border-radius: 4.17vw;
  }

  /* 왼쪽 텍스트 영역 */
  footer .top .left {
    width: 100%;
    text-align: center;
  }

  footer .top .left h2 {
    font-size: 5vw;
    line-height: 130%;
    padding-bottom: 3.75vw;
  }

  footer .top .left .mobileLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3.75vw; /* 18px/480 */
  }

  footer .top .left .mobileLogo img {
    width: 18.33vw; /* 64px/480 */
    height: 18.33vw; /* 64px/480 */
  }

  /* 버튼 컨테이너 */
  footer .top .btn {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1.083vw;
  }

  /* 각 스토어 버튼 */
  footer .top .btn a {
    width: 33.75vw;
    height: 12.167vw;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0.208vw 0.208vw 3.33vw rgba(109, 109, 109, 0.1);
    border-radius: 5.84vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.777vw;
    margin: 0; /* 기존 마진 제거 */
    padding: 0;
    color: #1a1a1a;
  }

  /* SVG 아이콘 크기 조정 */
  footer .top .btn a svg {
    width: 4.5vw;
    height: 4.5vw;
    margin-right: 2.083vw;
  }

  /* 오른쪽 영역 숨기기 */
  footer .top .right {
    display: none;
  }

  /* 문단 숨기기 */
  footer .top .left .paragraph {
    display: none;
  }

  /* 모바일용 새로운 그라데이션 박스 */
  footer .top-mobile {
    width: calc(100% - 8.33vw); /* 40px 여백 */
    height: 59.58vw; /* 286px/480*100 */
    margin: 0 4.167vw; /* 20px/480*100 */
    padding: 8.33vw 4.375vw; /* 40px 21px/480*100 */
    background: linear-gradient(90deg, #bcffe0 0%, #c6c4ff 100%);
    border-radius: 6.25vw; /* 30px/480*100 */
    border: 0.833vw rgba(0, 0, 0, 0.1) solid; /* 4px/480*100 */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.75vw; /* 18px/480*100 */
  }

  /* 하단 영역 */
  .bottom-container {
    flex-direction: column;
    margin-top: 11.375vw; /* 45px/480*100 */
  }

  /* 로고 및 소셜 링크 */
  .bottom-left {
    width: 100%;
    justify-content: space-between;
  }

  .logo {
    width: 30.875vw; /* 129.18px/480*100 */
    height: 9.917vw; /* 38px/480*100 */
  }

  .social-icon {
    width: 10.083vw; /* 34px/480*100 */
    height: 10.083vw;
  }

  .bottom-nav {
    padding-top: 4vw;
    border-bottom: 0.408vw solid rgba(217, 217, 217, 0.1);
  }

  /* 네비게이션 링크 */
  .nav-links {
    width: 100%;
    display: flex;
    flex-wrap: wrap; /* 줄바꿈 허용 */
    gap: 4.333vw; /* 16px/480*100 */
  }

  .nav-links li a {
    font-size: 3.33vw; /* 12px/480*100 */
    color: #c4c4c4;
  }

  .nav-links li:last-child {
    width: 100%;
    margin-bottom: 4.333vw; /* 40px/480*100 - 마지막 아이템 아래 여백 추가 */
  }

  /* 회사 정보 */
  footer .info {
    width: 100%;
    padding: 0;
    margin-bottom: 10vw;
  }

  footer .info h3 {
    font-size: 3.508vw; /* 13px */
    color: #7b7b7b;
    font-weight: 400;
    line-height: 2.917vw; /* 14px */
    margin-bottom: 2.433vw; /* 28px */
  }

  footer .info .details {
    display: flex;
    flex-direction: column;
    gap: 1.6vw; /* 20px */
  }

  footer .info .details .info-row {
    font-size: 3vw;
    color: #7b7b7b;
    font-weight: 200;
    line-height: 2.917vw;
    white-space: nowrap;
    display: flex;
    gap: 4.167vw;
  }

  footer .info .details .info-row a {
    color: #7d7ae0;
    text-decoration: underline;
    margin-left: 2.083vw;
  }

  footer .info span {
    display: block;
    margin-top: 1.167vw; /* 20px */
    font-size: 2.73vw; /* 10px */
    color: #7b7b7b;
    font-weight: 300;
  }
}

/* 480px 초과 시에는 숨김 */
@media screen and (min-width: 481px) {
  footer .mobileLogo {
    display: none;
  }
}
