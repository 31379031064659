.about-fourth {
  max-width: 1920px;
  margin: 0 auto;
  min-height: 100vh; /* 최소 높이 설정 */
  position: relative; /* 추가 */
}

.about-fourth .wrapper {
  position: relative;
  margin-top: 5vw;
}

.about-fourth .wrapper .top {
  display: block;
}

.about-fourth .top {
  width: 100%;
  margin-bottom: 4.17vw; /* 80px -> 4.17vw */
  padding-left: 16.67vw; /* 320px -> 16.67vw */
  transform: translateX(-10.5vw); /* 왼쪽으로 밀기 */
}

/*common*/
.about-fourth .top h3 {
  padding-left: 0.26vw; /* 5px -> 0.26vw */
  font-size: 1.25vw; /* 18px -> 0.94vw */
  background: linear-gradient(90deg, #bcffe0, #c6c4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 300;
}
.about-fourth .top h2 {
  font-weight: 700;
  font-size: 3vw; /* 50px -> 2.60vw */
  line-height: 3.125vw; /* 60px -> 3.125vw */
  letter-spacing: -0.052vw; /* -1px -> 0.052vw */
  padding: 0.94vw 0 1.3vw 0; /* 18px 0 25px 0 -> 0.94vw 0 1.30vw 0 */
}
.about-fourth .top h2 span {
  font-weight: 200;
}
.about-fourth .top p {
  font-size: 1.2vw; /* 20px -> 1.04vw */
  letter-spacing: -0.021vw; /* -0.4px -> 0.021vw */
  font-weight: 200;
  line-height: 150%;
  color: #d9d9d9;
  opacity: 0.8;
}

.about-fourth .bottom {
  width: 100%;
  position: relative;
}

.about-fourth .bottom .main {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
}

.about-fourth .bottom .main .img {
  width: 12.24vw;
  height: 25.36vw;
}

.about-fourth .bottom .main span {
  color: #1a1a1a;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  z-index: 1;
  left: -40%;
  top: 6%;
  text-align: center;
  font-size: 1.25vw; /* 24px -> 1.25vw */
  width: 15.1vw; /* 290px -> 15.10vw */
  height: 2.81vw; /* 54px -> 2.81vw */
  border-radius: 5.21vw; /* 100px -> 5.21vw */
  line-height: 2.81vw; /* 54px -> 2.81vw */
}

.about-fourth .bottom .left {
  position: absolute;
  left: 10vw;
}
.about-fourth .bottom .left img:first-child {
  position: absolute;
  left: 18vw;
  top: 10vw;
  width: 9.48vw;
  height: 13.75vw;
}
.about-fourth .bottom .left img:nth-child(3) {
  position: absolute;
  left: 8vw;
  top: 2vw;
  width: 9.06vw;
  height: 12.4vw;
}
.about-fourth .bottom .left img:last-child {
  position: absolute;
  left: 6vw;
  top: 15.5vw;
  width: 5.1vw;
  height: 8.18vw;
}

.about-fourth .bottom .left span:nth-child(2) {
  position: absolute;
  left: 14vw;
  top: 17.5vw;
  width: 14vw;
  height: 2.4vw;
}

.about-fourth .bottom .left span:nth-child(4) {
  position: absolute;
  left: 3vw;
  top: 9vw;
  width: 12.08vw;
  height: 2.4vw;
}
.about-fourth .bottom .left span,
.about-fourth .bottom .right span {
  color: #ababab;
  background: #5555;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;

  font-size: 1.04vw; /* 20px -> 1.04vw */
  line-height: 1.14vw; /* 54px -> 2.81vw */
  padding: 0.68vw 0.68vw; /* 13px 25px -> 0.68vw 1.30vw */
  border-radius: 2.6vw; /* 50px -> 2.60vw */
}

.about-fourth .bottom .right {
  position: absolute;
  right: 10vw;
}
.about-fourth .bottom .right img:first-child {
  position: absolute;
  right: 18.5vw;
  top: 1vw;
  width: 8.85vw;
  height: 12.5vw;
}

.about-fourth .bottom .right img:nth-child(3) {
  position: absolute;
  right: 18.5vw;
  top: 15vw;
  width: 5.31vw;
  height: 7.81vw;
}

.about-fourth .bottom .right img:nth-child(5) {
  position: absolute;
  right: 8vw;
  top: 5vw;
  width: 9.38vw;
  height: 11.77vw;
}

.about-fourth .bottom .right span {
  position: absolute;
}
.about-fourth .bottom .right span:nth-child(2) {
  position: absolute;
  right: 20vw;
  top: 7.5vw;
  width: 12.5vw;
  height: 2.4vw;
}

.about-fourth .bottom .right span:nth-child(4) {
  position: absolute;
  right: 10.5vw;
  top: 17vw;
  width: 12.4vw;
  height: 2.4vw;
}

/* Circle Container Positions - 1920px 기준 */
.circle-container {
  position: absolute;
}

/* Circle1 위치 */
.about-fourth .bottom .left .circle-container:nth-of-type(1) {
  left: 15vw;
  top: 16.5vw;
  width: 7.19vw;
  height: 7.19vw;
}

/* Circle2 위치 */
.about-fourth .bottom .left .circle-container:nth-of-type(2) {
  left: 5vw;
  top: 6vw;
  width: 8.96vw;
  height: 8.96vw;
}

/* Circle3 위치 */
.about-fourth .bottom .right .circle-container:nth-of-type(1) {
  right: 24vw;
  top: 5.5vw;
  opacity: 0.8;
  width: 6.46vw;
  height: 6.46vw;
}

/* Circle4 위치 */
.about-fourth .bottom .right .circle-container:nth-of-type(2) {
  right: 16vw;
  top: 15.5vw;
  opacity: 0.8;
  width: 5.1vw;
  height: 5.1vw;
}

/*animation*/
.bottom:not(.animate-start) img,
.bottom:not(.animate-start) span {
  opacity: 0;
  visibility: hidden; /* 추가 */
}

.animate-start img,
.animate-start span {
  visibility: visible; /* 추가 */
}

/* 애니메이션은 animate-start 클래스가 있을 때만 실행 */
.animate-start .left img:first-child,
.animate-start .right img:nth-child(3) {
  opacity: 0;
  animation: slide-up 0.3s ease-out forwards;
  animation-delay: 0.4s;
}

.animate-start .left img:nth-child(3),
.animate-start .right img:nth-child(5) {
  opacity: 0;
  animation: slide-up 0.9s ease-out forwards;
  animation-delay: 1.2s;
}

.animate-start .left img:nth-child(5),
.animate-start .right img:first-child {
  opacity: 0;
  animation: slide-up2 0.6s ease-out forwards;
  animation-delay: 0.8s;
}

/* text */
.animate-start .left span:nth-child(2),
.animate-start .right span:nth-child(4) {
  opacity: 0;
  animation: slide-up 0.3s ease-out forwards;
  animation-delay: 0.6s;
}

.animate-start .right span:nth-child(2) {
  opacity: 0;
  animation: slide-up 0.3s ease-out forwards;
  animation-delay: 1.4s;
}

.animate-start .left span:nth-child(4) {
  opacity: 0;
  animation: slide-up 0.3s ease-out forwards;
  animation-delay: 1.8s;
}

@keyframes slide-up {
  0% {
    transform: translateY(150px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-up2 {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.sticky-container {
  position: relative;
  min-height: 100vh;
  transition: all 0.5s ease;
}

.sticky-container.dimmed::before {
  content: "";
  position: absolute; /* absolute에서 fixed로 변경 */
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
  transition: background-color 0.5s ease;
  pointer-events: none;
}

.sticky-container.dimmed .wrapper {
  transition: filter 0.5s ease;
}

.achievement-popup {
  position: relative; /* relative에서 absolute로 변경 */
  left: 50%;
  top: 30%;
  transform: translate(-50%, -30%);
  max-width: 1280px; /* 컨테이너 최대 너비에 맞춤 */
  height: 100vh;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.77vw;
  animation: fadeIn 0.5s ease-out;
}

/* sticky-container의 position도 확인 */
.sticky-container {
  position: relative;
  min-height: 100vh; /* 추가 - 전체 높이 확보 */
  transition: all 0.5s ease;
}

.bottom-text {
  text-align: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.achievement-popup .top-achievement > div {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem 3rem;
  border-radius: 9999px;
  background: transparent;
  width: 25.17vw;
  height: 5.73vw;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 18.934px 0px rgba(255, 142, 94, 0.6);
}

.achievement-popup .top-achievement > div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 9999px;
  border: 0.246vw solid transparent;
  background: linear-gradient(90deg, #ff6e4e 0%, #ffa945 100%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  box-shadow: 0px 0px 18.934px 0px rgba(255, 142, 94, 0.6);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}
.achievement-popup .top-achievement > div span {
  font-size: 2.5vw; /* 48px -> 2.50vw */
  color: #ff6b00;
}

.achievement-popup .bottom-text span {
  font-size: 2.6vw; /* 50px -> 2.60vw */
  color: white;
  text-align: center;
  display: block;
  line-height: 1.4;
}

.about-fourth .bottom .left span.empty-space,
.about-fourth .bottom .right span.empty-space {
  visibility: hidden; /* display: none 대신 visibility: hidden 사용 */
  pointer-events: none; /* 상호작용 방지 */
  user-select: none; /* 텍스트 선택 방지 */
  height: 2vw; /* 기존 span과 동일한 높이 유지 */
}

/* 1280px 이하 적응형 */
@media screen and (max-width: 1280px) {
  .about-fourth {
    width: 1080px;
    min-width: 1080px;
    margin: 0 auto;
  }

  .about-fourth .wrapper {
    width: 1080px;
    margin-top: 10px;
  }

  .about-fourth .top {
    margin-bottom: 53px;
  }

  /* 공통 텍스트 스타일 */
  .about-fourth .top h3 {
    padding-left: 3px;
    font-size: 18px;
  }

  .about-fourth .top h2 {
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -1px;
    padding: 18px 0 25px 0;
  }

  .about-fourth .top p {
    font-size: 20px;
    letter-spacing: -0.4px;
  }

  /* 메인 이미지 */
  .about-fourth .bottom .main .img {
    width: 157px;
    height: 325px;
  }

  .about-fourth .bottom .main span {
    font-size: 16px;
    width: 193px;
    height: 36px;
    border-radius: 67px;
    line-height: 36px;
  }

  /* 왼쪽 이미지 및 텍스트 */
  .about-fourth .bottom .left {
    left: 90px;
  }

  .about-fourth .bottom .left img:first-child {
    left: 230px;
    top: 128px;
    width: 121px;
    height: 176px;
  }

  .about-fourth .bottom .left img:nth-child(3) {
    left: 102px;
    top: 26px;
    width: 116px;
    height: 159px;
  }

  .about-fourth .bottom .left img:last-child {
    left: 77px;
    top: 198px;
    width: 65px;
    height: 105px;
  }

  .about-fourth .bottom .left span:nth-child(2) {
    left: 179px;
    top: 224px;
    width: 179px;
    height: 31px;
  }

  .about-fourth .bottom .left span:nth-child(4) {
    left: 38px;
    top: 115px;
    width: 155px;
    height: 31px;
  }

  /* 오른쪽 이미지 및 텍스트 */
  .about-fourth .bottom .right {
    right: 90px;
  }

  .about-fourth .bottom .right img:first-child {
    right: 237px;
    top: 13px;
    width: 113px;
    height: 160px;
  }

  .about-fourth .bottom .right img:nth-child(3) {
    right: 237px;
    top: 192px;
    width: 68px;
    height: 100px;
  }

  .about-fourth .bottom .right img:nth-child(5) {
    right: 102px;
    top: 64px;
    width: 120px;
    height: 151px;
  }

  .about-fourth .bottom .right span:nth-child(2) {
    right: 256px;
    top: 96px;
    width: 160px;
    height: 31px;
  }

  .about-fourth .bottom .right span:nth-child(4) {
    right: 134px;
    top: 218px;
    width: 159px;
    height: 31px;
  }

  /* 공통 span 스타일 */
  .about-fourth .bottom .left span,
  .about-fourth .bottom .right span {
    font-size: 13px;
    line-height: 15px;
    padding: 9px 9px;
    border-radius: 33px;
  }

  /* 서클 컨테이너 위치 */
  .about-fourth .bottom .left .circle-container:nth-of-type(1) {
    left: 192px;
    top: 211px;
    width: 92px;
    height: 92px;
  }

  .about-fourth .bottom .left .circle-container:nth-of-type(2) {
    left: 64px;
    top: 77px;
    width: 115px;
    height: 115px;
  }

  .about-fourth .bottom .right .circle-container:nth-of-type(1) {
    right: 307px;
    top: 70px;
    width: 83px;
    height: 83px;
  }

  .about-fourth .bottom .right .circle-container:nth-of-type(2) {
    right: 205px;
    top: 198px;
    width: 65px;
    height: 65px;
  }

  /* 팝업 스타일 */
  .achievement-popup {
    max-width: 1080px;
    height: 100vh;
  }

  .achievement-popup .top-achievement > div {
    width: 322px;
    height: 73px;
  }

  .achievement-popup .top-achievement > div::before {
    border-width: 3px;
  }

  .achievement-popup .top-achievement > div span {
    font-size: 32px;
  }

  .achievement-popup .bottom-text span {
    font-size: 33px;
  }

  .about-fourth .bottom .left span.empty-space,
  .about-fourth .bottom .right span.empty-space {
    height: 26px;
  }
}

@media screen and (max-width: 480px) {
  .about-fourth {
    width: 100%;
    min-width: auto;
    margin-top: 0; /* 마진 제거 */
    height: 100vh; /* 뷰포트 높이로 고정 */
    display: flex;
    align-items: center;
    overflow: hidden; /* 오버플로우 방지 */
  }

  .about-fourth .wrapper {
    padding-top: 15vw;
    width: 100%;
  }

  /* 텍스트 영역 */
  .about-fourth .top {
    width: 100%;
    margin-bottom: 8.33vw;
    padding-left: 6.25vw; /* 20px */
    transform: none;
  }

  .about-fourth .top h3 {
    font-size: 4.7vw; /* 14px */
    padding-left: 0;
    margin-bottom: 1.58vw;
  }

  .about-fourth .top h2 {
    font-size: 7.5vw; /* 28px */
    line-height: 8.33vw; /* 40px */
    letter-spacing: -0.21vw;
    padding: 2.08vw 0;
    margin-bottom: 1.58vw;
    font-weight: 700;
  }

  .about-fourth .top p {
    font-size: 4.3vw; /* 13px */
    line-height: 140%;
    letter-spacing: -0.1vw;
    color: #9d9d9d;
  }

  /* 메인 모바일 영역 */
  .about-fourth .bottom {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12vw;
  }

  .about-fourth .bottom .main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    left: auto;
    transform: none;
  }

  .about-fourth .bottom .main .img {
    width: 41.67vw; /* 300px */
    height: 87.08vw;
  }

  .about-fourth .bottom .main span {
    position: absolute;
    left: -30%;
    top: auto;
    width: 36.25vw;
    height: 6.875vw;
    font-size: 3.4vw;
    line-height: 7vw;
  }

  .about-fourth .bottom .left {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }

  .about-fourth .bottom .left img:first-child {
    position: absolute;
    left: 4.17vw;
    top: 8.33vw;
    width: 20vw;
    height: 32.17vw;
  }

  .about-fourth .bottom .left img:nth-child(3) {
    position: absolute;
    left: 4.17vw;
    top: 36.67vw;
    width: 20vw;
    height: 32.17vw;
  }

  .about-fourth .bottom .left img:last-child {
    position: absolute;
    left: 4.17vw;
    top: 64vw;
    width: 20vw;
    height: 32.17vw;
  }

  .about-fourth .bottom .left span:nth-child(2) {
    position: absolute;
    left: 10vw;
    top: 25.5vw;
    width: 32.417vw;
    height: 7.5vw;
    font-size: 3vw;
  }

  .about-fourth .bottom .left span:nth-child(4) {
    position: absolute;
    left: 56vw;
    top: 65vw;
    width: 27.417vw;
    height: 7.5vw;
    font-size: 3vw;
  }

  .about-fourth .bottom .right {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
  }

  .about-fourth .bottom .right img:first-child {
    position: absolute;
    right: 4.17vw;
    top: 8.33vw;
    width: 20vw;
    height: 32.17vw;
  }

  .about-fourth .bottom .right img:nth-child(3) {
    position: absolute;
    right: 4.17vw;
    top: 36.67vw;
    width: 20vw;
    height: 32.17vw;
  }

  .about-fourth .bottom .right img:last-child {
    position: absolute;
    right: 4.17vw;
    top: 64vw;
    width: 20vw;
    height: 32.17vw;
  }

  .about-fourth .bottom .right span:nth-child(2) {
    position: absolute;
    right: 48vw;
    top: 58.5vw;
    width: 29vw;
    height: 7.5vw;
    font-size: 3vw;
  }

  .about-fourth .bottom .right span:nth-child(4) {
    position: absolute;
    right: 8vw;
    top: 17vw;
    width: 28vw;
    height: 7.5vw;
    font-size: 3vw;
  }

  .circle-container {
    z-index: 1;
  }

  /* Circle SVG 내부 텍스트 변환 */
  .circle-container svg text {
    font-size: 3.667vw !important; /* 27.227px -> 5.667vw */
  }

  .circle-container svg text:nth-child(2) {
    font-size: 6.829vw !important; /* 34.698px -> 7.229vw */
  }

  .circle-container svg text:nth-child(3) {
    font-size: 7.94vw !important; /* 14.114px -> 2.94vw */
    letter-spacing: 0.1vw !important; /* -1.041px -> -0.217vw */
  }

  /* Circle1 위치 */
  .about-fourth .bottom .left .circle-container:nth-of-type(1) {
    left: 55vw;
    top: 54.5vw;
  }

  /* Circle2 위치 */
  .about-fourth .bottom .left .circle-container:nth-of-type(2) {
    left: 56vw;
    top: 19vw;
  }

  /* Circle3 위치 */
  .about-fourth .bottom .right .circle-container:nth-of-type(1) {
    right: 48vw;
    top: 22vw;
    opacity: 0.8;
  }

  /* Circle4 위치 */
  .about-fourth .bottom .right .circle-container:nth-of-type(2) {
    right: 57vw;
    top: 50.5vw;
    opacity: 0.8;
  }

  /* Circle2 */
  .about-fourth .bottom .left .circle-container:nth-of-type(1) svg {
    width: 21.417vw; /* 98px */
    height: 21.417vw;
  }

  /* Circle1 */
  .about-fourth .bottom .left .circle-container:nth-of-type(2) svg {
    width: 13.542vw;
    height: 13.542vw;
  }

  /* Circle4 */
  .about-fourth .bottom .right .circle-container:nth-of-type(1) svg {
    width: 8.333vw;
    height: 8.333vw;
  }

  /* Circle3 */
  .about-fourth .bottom .right .circle-container:nth-of-type(2) svg {
    width: 15.042vw;
    height: 15.042vw;
  }

  .sticky-container {
    position: relative;
    height: 100vh;
    width: 100%;
  }

  .achievement-popup {
    position: absolute; /* absolute에서 fixed로 변경 */
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; /* 418px */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4.333vw; /* 40px */
    z-index: 100;
  }

  .achievement-popup .top-achievement > div {
    width: 48.333vw; /* 174px */
    height: 12.083vw; /* 33px */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 1vw;
  }

  .achievement-popup .top-achievement > div span {
    font-size: 5.167vw; /* 20px */
    color: #ff6b00;
  }

  .achievement-popup .bottom-text span {
    font-size: 4.667vw; /* 20px */
    color: white;
    text-align: center;
    line-height: 140%;
  }

  .sticky-container.dimmed::before {
    position: absolute; /* absolute에서 fixed로 변경 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;
  }

  .sticky-container.dimmed .wrapper .top,
  .sticky-container.dimmed .wrapper .bottom {
    opacity: 0.7;
    transition: opacity 0.5s ease;
  }

  /* 기본 상태의 transition 추가 */
  .sticky-container .wrapper .top,
  .sticky-container .wrapper .bottom {
    transition: opacity 0.5s ease;
  }
}

/* 애니메이션 키프레임 수정 */
@keyframes slide-up {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-up2 {
  0% {
    transform: translateY(150px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
