.container {
  width: 100%;
  height: 2700px;
  margin: 0 auto;
  max-width: 1920px;
}
.visual {
  height: 100vh;
}
.visual .inner {
  position: relative;
}
.visual .up {
  width: 100%;
  position: absolute;
}
.visual .up .bg {
  max-width: 1920px;
  width: 100%;
  height: 48.85vw;
  border-radius: 3.125vw;
  background: linear-gradient(90deg, #bcffe0, #c6c4ff);
}

.visual .up .elements {
  position: absolute;
  z-index: 21;
}

.visual .up .elements .svg-icon {
  position: absolute;
}
.visual .up .elements .svg-icon:nth-child(1) {
  top: 21vw;
  left: 29vw;
  width: 10.26vw; /* 197px -> (197/1920)*100 = 10.26vw */
  height: 10.05vw; /* 193px -> (193/1920)*100 = 10.05vw */
}

.visual .up .elements .svg-icon:nth-child(2) {
  left: 30.41vw;
  top: 35.89vw;
  width: 6.93vw; /* 133px -> (133/1920)*100 = 6.93vw */
  height: 8.54vw; /* 164px -> (164/1920)*100 = 8.54vw */
}

.visual .up .elements .svg-icon:nth-child(3) {
  top: 41.02vw;
  left: 44.33vw;
  width: 15.83vw; /* 304px -> (304/1920)*100 = 15.83vw */
  height: 3.02vw; /* 58px -> (58/1920)*100 = 3.02vw */
}

.visual .down {
  width: 100%;
  position: absolute;
}
.visual .down .txt {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.visual .down .txt p {
  color: #645dd1;
  padding: 5.208vw 0 0.521vw 0;
  font-size: 1.354vw;
  font-weight: 300;
}

.visual .down .txt h2 {
  font-size: 3.125vw; /* 60px -> 3.125vw */
  font-weight: lighter;
  letter-spacing: -0.21vw; /* -4px -> -0.21vw */
  line-height: 1.2; /* line-height 수정 */
  color: #1d1d1d;
  white-space: nowrap; /* 텍스트 줄바꿈 방지 */
}

.visual .down .txt h2 span {
  font-weight: bold;
}

.visual .down .device {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 15vw;
  z-index: 20;
}
.visual .down .device .img {
  width: 22.03vw; /* 423px -> (423/1920)*100 = 22.03vw */
  height: 45.63vw; /* 876px -> (876/1920)*100 = 45.63vw */
}

.body {
  margin-top: 4.17vw; /* 80px -> 4.17vw */
  width: 100%;
  height: 100vh;
  position: relative;
  top: 40vh;
}
.body .inner {
  width: 19.79vw; /* 380px -> 19.79vw */
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* map 이미지에 대한 스타일 추가 */
.body .inner .map-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  z-index: 10;
}

.body .inner .fullLogo {
  width: 9.269vw;
  height: 9.136vw;
  filter: drop-shadow(
    0px 0px 2.08vw rgba(148, 254, 207, 0.6)
  ); /* 40px -> 2.08vw */
  z-index: 30;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute; /* z-index가 제대로 작동하도록 position 설정 */
}

.body .inner .fullLogo img {
  width: 100%;
  height: 100%;
}

.body .circle {
  position: absolute;
}
.body .circle .won {
  position: absolute;
  width: 900px;
  height: 900px;
  border: 3px solid transparent;
  border-radius: 50%;
  background-image: linear-gradient(#000, #000),
    linear-gradient(180deg, #adfdde 0%, #d0d3fe 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  opacity: 0.5;
  filter: blur(3px);
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
}
/* 스크롤에 따른 기본 크기 변화는 GSAP에서 처리하므로 기존 애니메이션은 제거 */
.body .circle .won {
  animation: none;
}
.body .circle .won:nth-child(1) {
  width: 28.65vw; /* 550px -> 28.65vw */
  height: 28.65vw;
  opacity: 0.3;
  filter: blur(2px);
  z-index: 16;
}
.body .circle .won:nth-child(2) {
  width: 14.22vw; /* 273px -> 14.22vw */
  height: 14.22vw;
  opacity: 0.3;
  filter: blur(0.5px);
  z-index: 17;
}
.body .circle .won:nth-child(3) {
  width: 50.68vw; /* 973px -> 50.68vw */
  height: 50.68vw;
  opacity: 0.3;
  filter: blur(0.5px);
  z-index: 18;
}
.body .circle .won img {
  position: absolute;
  z-index: 100;
}

/*circle Ani*/
@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* .body .circle .won {
  animation: spin 10s linear infinite; 
} */

/*dots*/
.body .dots {
  width: 100%;
  position: absolute;
}

.body .dots .glow-circle {
  position: relative;
  width: 0.94vw; /* 18px -> 0.94vw */
  height: 0.94vw;
  background-color: #73f2bc;
  border-radius: 50%;
  animation: glow 3s infinite ease-in-out;
  z-index: 100;
}

.body .dots .glow-circle::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.56vw; /* 30px -> 1.56vw */
  height: 1.56vw;
  background-color: rgba(115, 242, 188, 0.3);
  border-radius: 50%;
  opacity: 1;
}

.body .dots .glow-circle:nth-child(1) {
  position: absolute;
  left: -8vw;
  top: -10vw;
}
.body .dots .glow-circle:nth-child(2) {
  position: absolute;
  left: -11vw;
  top: 9vw;
}
.body .dots .glow-circle:nth-child(3) {
  position: absolute;
  left: 4vw;
  top: 12vw;
}
.body .dots .glow-circle:nth-child(4) {
  position: absolute;
  left: 26vw;
  top: -15vw;
}
.body .dots .glow-circle:nth-child(5) {
  position: absolute;
  left: 23.7vw;
  top: 0vw;
}
.body .dots .glow-circle:nth-child(6) {
  position: absolute;
  left: 25vw;
  top: 12vw;
}
.body .dots .glow-circle:nth-child(7) {
  position: absolute;
  left: 28vw;
  top: 7vw;
}

/*dot Ani*/
@keyframes glow {
  0%,
  100% {
    transform: scale(0.8);
    opacity: 0.3;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
}

.visual .down .txt h2 span {
  font-weight: bold;
}

.body .txt {
  color: #fff;
  text-align: center;
}

.body .txt p {
  color: #9d9d9d;
  font-weight: 300;
  padding: 10.21vw 0 0.52vw 0; /* 100px, 10px -> 5.21vw, 0.52vw */
  font-size: 1.35vw; /* 26px -> 1.35vw */
}

.body .txt h2 {
  font-size: 3.125vw; /* 60px -> 3.125vw */
  line-height: 1.2; /* line-height 수정 */
}

.body .txt h2 span {
  color: #94fecf;
}
/* 1920px - 1280px 반응형 */
@media screen and (max-width: 1920px) {
  .visual .up .elements img:nth-child(1) {
    top: calc(400px + (21vw - 400px) * ((1920px - 100vw) / 640));
    left: calc(540px + (28vw - 540px) * ((1920px - 100vw) / 640));
  }

  .visual .up .elements img:nth-child(2) {
    left: calc(580px + (30vw - 580px) * ((1920px - 100vw) / 640));
    top: calc(670px + (35vw - 670px) * ((1920px - 100vw) / 640));
  }

  .visual .up .elements img:nth-child(3) {
    top: calc(730px + (38vw - 730px) * ((1920px - 100vw) / 640));
    left: calc(880px + (46vw - 880px) * ((1920px - 100vw) / 640));
  }
}

/* 1280px 이하 적응형 */
@media screen and (max-width: 1280px) {
  .container {
    width: 1080px; /* 고정 너비로 변경 */
    min-width: 1080px; /* 최소 너비도 설정 */
  }

  .visual .up .bg {
    width: 1080px; /* bg도 고정 너비로 설정 */
    height: 720px;
    border-radius: 40px;
  }

  .visual .up .elements {
    position: absolute;
    width: 1080px;
    height: 720px;
    transform: none !important; /* GSAP 애니메이션 무시 */
  }
  .visual .up .elements .svg-icon:nth-child(1) {
    position: absolute;
    top: -420px;
    left: 352px;
    width: 123px;
    height: 120px;
  }

  .visual .up .elements .svg-icon:nth-child(2) {
    position: absolute;
    top: -250px;
    left: 372px;
    width: 82px;
    height: 102px;
  }

  .visual .up .elements .svg-icon:nth-child(3) {
    position: absolute;
    top: -190px;
    left: 538px;
    width: 190px;
    height: 36px;
  }

  .visual .down {
    width: 100%;
    position: absolute;
  }

  .visual .down .txt p {
    padding: 66px 0 0 0;
    font-size: 22px;
    margin-bottom: 20px;
  }

  .visual .down .txt h2 {
    font-size: 44px;
    letter-spacing: -1.76px;
  }

  .visual .down .device {
    top: 207px;
  }

  .visual .down .device .img {
    width: 264px;
    height: 546px;
  }

  .body {
    margin-top: 53px;
  }

  .body .inner {
    width: 253px;
  }

  .body .inner .map-image {
    position: absolute;
    width: 1080px;
    height: 100%;
  }

  .body .inner .fullLogo {
    width: 94px;
    height: 93px;
    filter: drop-shadow(0px 0px 27px rgba(148, 254, 207, 0.6));
  }

  .body .circle .won:nth-child(1) {
    width: 367px;
    height: 367px;
  }

  .body .circle .won:nth-child(2) {
    width: 182px;
    height: 182px;
  }

  .body .circle .won:nth-child(3) {
    width: 649px;
    height: 649px;
  }

  .body .dots .glow-circle {
    width: 12px;
    height: 12px;
  }

  .body .dots .glow-circle::before {
    width: 20px;
    height: 20px;
  }

  /* dots 위치 조정 */
  .body .dots .glow-circle:nth-child(1) {
    left: -102px;
    top: -128px;
  }
  .body .dots .glow-circle:nth-child(2) {
    left: -141px;
    top: 115px;
  }
  .body .dots .glow-circle:nth-child(3) {
    left: 51px;
    top: 154px;
  }
  .body .dots .glow-circle:nth-child(4) {
    left: 333px;
    top: -192px;
  }
  .body .dots .glow-circle:nth-child(5) {
    left: 303px;
    top: 0;
  }
  .body .dots .glow-circle:nth-child(6) {
    left: 320px;
    top: 154px;
  }
  .body .dots .glow-circle:nth-child(7) {
    left: 358px;
    top: 90px;
  }

  .body .txt {
    margin-top: 100px;
  }

  .body .txt p {
    padding: 0px 0 7px 0;
    font-size: 17px;
  }

  .body .txt h2 {
    font-size: 40px;
  }
}

/* 480px 이하 모바일 레이아웃 */
@media screen and (max-width: 480px) {
  .container {
    width: 100%;
    height: 2000px;
    min-width: initial;
    overflow-x: hidden;
    padding-top: 6vw;
  }

  .visual {
    height: 180vw; /* vh 대신 vw 사용하여 비율 유지 */
    padding-bottom: 20vw;
  }

  .visual .up {
    width: 100%;
    position: absolute;
    left: 0;
  }

  .visual .up .bg {
    width: 100%;
    height: 180vw;
    border-radius: 10.167vw; /* 20px */
  }

  .visual .up .elements {
    width: 100%;
    height: auto;
    position: absolute;
    top: 58.333vw; /* 280px */
    left: 0;
    margin: 0;
  }

  .visual .up .elements .svg-icon {
    position: absolute;
  }

  /* 기존 img 스타일을 SVG 컴포넌트에 맞게 수정 */
  .visual .up .elements .svg-icon:nth-child(1) {
    top: 14vw;
    left: 2vw;
    width: 32.292vw;
    height: 36.875vw;
  }

  .visual .up .elements .svg-icon:nth-child(2) {
    top: 52vw;
    left: 8vw;
    width: 24.792vw; /* 71px */
    height: 28.333vw; /* 88px */
  }

  .visual .up .elements .svg-icon:nth-child(3) {
    top: 80vw;
    left: 40vw;
    width: 44.208vw; /* 169px */
    height: 15.667vw; /* 32px */
  }

  .visual .down .device {
    top: 58vw; /* 220px */
  }

  .visual .down .device .img {
    width: 55vw; /* 185px */
    height: 110vw; /* 384px */
  }

  .visual .down {
    width: 100%;
    left: 0;
  }

  .visual .down .txt {
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 90vw; /* 추가: 더 넓은 영역 확보 */
  }

  .visual .down .txt p {
    padding: 1vw;
    font-size: 3.5vw;
    padding-top: 8.333vw; /* 40px */
    margin-bottom: 1vw;
  }

  .visual .down .txt h2 {
    font-size: 7vw;
    letter-spacing: -0.104vw; /* -0.5px */
    white-space: normal;
  }

  .visual .down .txt h2 span {
    font-weight: 700;
  }

  .visual .down .txt h2 br {
    display: inline;
  }

  .body {
    top: 0vw;
  }

  .body .inner {
    width: 100%;
  }

  .body .inner .map-image {
    width: 100%;
    height: auto;
  }

  .body .inner .fullLogo {
    width: 18.417vw; /* 50px */
    height: 18.417vw; /* 50px */
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
  }
  .body .circle .won {
    top: 20vw;
    opacity: 1;
  }
  .body .circle .won:nth-child(1) {
    width: 41.667vw; /* 200px */
    height: 41.667vw; /* 200px */
  }

  .body .circle .won:nth-child(2) {
    width: 20.833vw; /* 100px */
    height: 20.833vw; /* 100px */
  }

  .body .circle .won:nth-child(3) {
    width: 62.5vw; /* 300px */
    height: 62.5vw; /* 300px */
  }

  .body .dots {
    width: 100%;
    position: absolute;
    top: 60%; /* 추가 */
    left: 90%; /* 추가 */
    transform: translate(-50%, -50%); /* 추가 */
    z-index: 20; /* 추가 */
  }

  .body .dots .glow-circle {
    width: 1.667vw; /* 8px */
    height: 1.667vw; /* 8px */
  }

  .body .dots .glow-circle::before {
    width: 2.917vw; /* 14px */
    height: 2.917vw; /* 14px */
  }

  .body .dots .glow-circle:nth-child(1) {
    left: -12.333vw; /* -40px */
    top: -35.5vw; /* -60px */
  }
  .body .dots .glow-circle:nth-child(2) {
    left: -28.5vw; /* -60px */
    top: 2.333vw; /* 40px */
  }
  .body .dots .glow-circle:nth-child(3) {
    left: -4.167vw; /* 20px */
    top: 26.5vw; /* 60px */
  }
  .body .dots .glow-circle:nth-child(4) {
    left: 34.167vw; /* 140px */
    top: -28.667vw; /* -80px */
  }
  .body .dots .glow-circle:nth-child(5) {
    left: 28.083vw; /* 130px */
    top: 2;
  }
  .body .dots .glow-circle:nth-child(6) {
    left: 32.167vw; /* 140px */
    top: 26.5vw; /* 60px */
  }
  .body .dots .glow-circle:nth-child(7) {
    left: 38.333vw; /* 160px */
    top: 8.333vw; /* 40px */
  }

  .body .txt {
    position: relative;
    top: -16vh;
  }

  .body .txt p {
    padding: 0 0 6vw; /* 60px 0 5px */
    font-size: 4.2vw; /* 14px */
  }

  .body .txt h2 {
    font-size: 7.2vw; /* 22px */
    padding: 0; /* 0 20px */
  }

  .body .txt .download-btn {
    display: block;
    margin: 0 auto;
    margin-top: 11.458vw; /* 55px */
    width: 44vw; /* 160px */
    height: 14vw; /* 48px */
    border-radius: 999vw; /* 24px */
    border: 0.208vw solid #bcffe0; /* 1px */
    background: #000;
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4.6vw; /* 16px */
    font-weight: 700;
  }

  .body .txt .download-btn:hover {
    border-radius: 7.813vw; /* 37.5px */
    background: linear-gradient(96deg, #bcffe0 6.83%, #c6c4ff 93.17%);
    color: #000; /* 배경이 밝아지므로 텍스트 색상을 어둡게 변경 */
    border: none; /* gradient 배경이므로 border 제거 */
  }

  .visual-btn {
    position: absolute;
    width: 100%;
    top: 34.833vw; /* 148px -> (148/480)*100 */
    display: flex;
    justify-content: center;
    gap: 2.083vw; /* 10px -> (10/480)*100 */
    left: 50%;
    transform: translateX(-50%);
  }

  .visual-btn a {
    border: 0.208vw solid #4d4d4d;
    border-radius: 10.167vw;
    padding: 3.5vw 4.2vw;
    font-size: 4.2vw;
    font-weight: 300;
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  .visual-btn a span {
    display: flex;
    align-items: center;
    margin-right: 1.667vw; /* 8px -> (8/480)*100 */
  }

  .store-btn-svg.apple-svg {
    width: 4.125vw;
    height: 4.75vw;
  }

  .store-btn-svg.google-svg {
    width: 4.333vw;
    height: 4.333vw;
  }
}

/* 480px 초과 시에는 숨김 */
@media screen and (min-width: 481px) {
  .visual-btn {
    display: none;
  }

  .visual .down .txt h2 br {
    display: none;
  }

  .body .txt .download-btn {
    display: none;
  }
}
