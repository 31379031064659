/* 1920px 이상 고정 레이아웃 */
.switch {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  z-index: 0;
  max-width: 1920px;
  margin: 0 auto;
}

.switch .inner {
  margin: 0 auto;
  font-size: 70px;
  text-align: center;
  position: relative;
  width: 100%;
  height: 400vh;
  display: flex;
  align-items: flex-start;
}

.switch .sticky-container {
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch h2 {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7),
    #bcffe0,
    #c6c4ff,
    rgba(0, 0, 0, 0.7)
  );
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  transition: transform 0.3s ease-out;
  will-change: transform, background-position;
  padding: 20px;
  line-height: 1.4;
}

/* 1920px - 1280px 반응형 */
@media screen and (max-width: 1920px) {
  .switch .inner {
    font-size: calc(50px + (70 - 50) * ((100vw - 1280px) / (1920 - 1280)));
  }

  .switch h2 {
    padding: calc(15px + (20 - 15) * ((100vw - 1280px) / (1920 - 1280)));
  }
}

/* 1280px - 480px 적응형 */
@media screen and (max-width: 1280px) {
  .switch {
    width: 1080px;
    min-width: 1080px;
  }

  .switch .inner {
    width: 100%; /* 고정 너비 제거 */
    font-size: 60px;
  }

  .switch .sticky-container {
    width: 100%;
    position: sticky;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .switch h2 {
    padding: 15px;
    position: relative;
    left: 30%;
    transform: translateX(-50%);
  }
}

/* 480px 이하 모바일 레이아웃 */
@media screen and (max-width: 480px) {
  .switch {
    width: 100%;
    min-width: initial;
    height: auto;
    overflow: hidden;
  }

  .switch .inner {
    height: 300vh;
  }

  .switch .sticky-container {
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: none !important;
  }

  .switch h2 {
    font-size: 10.5vw;
    position: relative;
    left: 50%; /* 중앙 정렬을 위해 수정 */
    width: auto;
    transform-origin: center center;
    left: 0;
    transform: none;
    margin: 0 auto;
    text-align: center;
    line-height: 1.3;
    word-break: keep-all; /* 단어 단위로 줄바꿈 */
    /* 배경 그라데이션 유지 */
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.7),
      #bcffe0,
      #c6c4ff,
      rgba(0, 0, 0, 0.7)
    );
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}
