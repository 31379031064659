header * {
  color: #fff;
  font-family: "Pretendard";
  background: #000;
}

.inner {
  width: 99%;
  max-width: 1980px;
  margin: 0 auto;
}

header ul {
  height: 7.292vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header ul li .logo img {
  width: 9.063vw;
  height: 2.292vw;
}

header ul li .btn a {
  border: 0.052vw solid #4d4d4d;
  border-radius: 2.604vw;
  padding: 0.938vw 1.563vw;
  font-size: 0.938vw;
  font-weight: 300;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  background: transparent;
}

header ul li .btn a svg {
  margin-right: 0.417vw;
  background: transparent;
  transition: all 0.3s ease;
  width: 1.563vw; /* SVG 크기 증가 */
  height: 1.823vw;
}

header ul li .btn a:last-child svg {
  width: 1.563vw;
  height: 1.823vw;
}

header ul li .btn a:hover {
  background: #ffffff;
  border-color: transparent;
  color: #000000;
}

header ul li .btn a:hover span {
  background: transparent;
}

header ul li .btn a:hover svg {
  width: 1.563vw;
  height: 1.823vw;
}

header ul li .btn a:hover svg path {
  fill: #000000;
}

header ul li .btn a span {
  vertical-align: middle;
}

header ul li .btn a:first-of-type {
  margin-right: 0.781vw;
}

@media screen and (max-width: 1280px) {
  body,
  html {
    width: 1280px;
    min-width: 1280px;
    overflow-x: scroll;
    background: #000;
  }

  header {
    width: 1080px;
    min-width: 1080px;
  }

  .inner {
    width: 1080px;
  }

  header ul {
    height: 120px;
  }

  header ul li .logo {
    padding-left: 24px;
  }

  header ul li .logo img {
    width: 174px;
    height: 44px;
  }

  header ul li .btn {
    margin-top: 20px;
    margin-right: -24px;
  }

  header ul li .btn a {
    border-width: 1px;
    border-radius: 33px;
    padding: 12px 20px;
    font-size: 18px;
  }

  header ul li .btn a svg {
    width: 21px;
    height: 25px;
  }

  header ul li .btn a:last-child svg {
    width: 21px;
    height: 24px;
  }

  header ul li .btn a:hover svg {
    width: 21px; /* 1280px 이하에서도 hover 시 크기 유지 */
    height: 25px;
  }
}

@media screen and (max-width: 480px) {
  body,
  html {
    width: 100%;
    min-width: initial;
    overflow-x: hidden;
    overflow-y: auto;
  }

  header {
    width: 100%;
    min-width: initial;
    left: 0;
  }

  .inner {
    width: 100%;
  }

  header ul {
    height: 60px;
  }

  header ul li .logo {
    padding-left: 0;
  }

  header ul li .logo img {
    width: 120px;
    height: 40px;
  }

  header ul li .btn {
    display: none;
  }
}
