.about-second {
  max-width: 1280px;
  margin: 0 auto;
}

.about-second .container {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 기존 스타일 유지 */
.about-second ul {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.about-second ul .left {
  padding-left: 10vw;
}

.about-second ul .left .banner {
  width: 36.875vw; /* 600px -> 46.875vw */
  height: 40.45vw; /* 569px -> 44.45vw */
  border-radius: 3.515625vw; /* 45px -> 3.515625vw */
  box-sizing: border-box;
  background: #1a1a1a;
  overflow: hidden;
  position: relative;
}

/* 새로운 애니메이션 관련 스타일 */
.frames-container {
  position: absolute;
  width: 100%;
}

@keyframes spin {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-7vw * 9)); /* 5개 프레임만큼 이동 */
  }
}

.frames-container.spinning {
  animation: spin 1.6s linear forwards;
  animation-iteration-count: 1; /* 한 번만 실행 */
}

.frames-container.completed {
  transform: translateY(calc(-7vw * 9)); /* 마지막 위치 유지 */
}

.frame {
  margin: 0 auto;

  width: 30.15625vw; /* 450px -> 35.15625vw */
  height: 6.59375vw; /* 110px -> 8.59375vw */
  line-height: 8.59375vw; /* 110px -> 8.59375vw */
  margin-bottom: 1.171875vw; /* 15px -> 1.171875vw */
  border-radius: 2.34375vw; /* 30px -> 2.34375vw */
  padding: 2.421875vw 2.8125vw; /* 31px 36px -> 2.421875vw 2.8125vw */
  color: #1a1a1a;
  display: flex;
  justify-content: space-between;
  background: #9a95ea;
  align-items: center;
  transition: all 0.3s ease; /* 모든 속성에 1초 트랜지션 적용 */
}

/* completed 상태일 때 청담/압구정을 제외한 모든 프레임의 투명도 변경 */
.frames-container.completed .frame:not(:nth-child(11)) {
  opacity: 0.6;
}

/* 청담/압구정 프레임 스타일 (5번째 아이템) */
.frames-container.completed .frame:nth-child(11) {
  background: linear-gradient(
    93deg,
    #fff7bf 0%,
    #ffbf69 54.52%,
    #ff5d0e 108.49%
  );
  border-radius: 2.279609375vw; /* 29.179px -> 2.279609375vw */
  border: 0.1425vw solid #eda80f; /* 1.824px -> 0.1425vw */
  box-shadow: 0px 0px 2.84953125vw 0px rgba(255, 75, 18, 0.7); /* 36.474px -> 2.84953125vw */
}

.frame.near {
  color: #94fecf;
  border: 2px solid #94fecf;
  background: #1a1a1a;
}

.frame:nth-child(1) {
  background: #c1c1ff;
}

.frame:nth-child(2) {
  background: linear-gradient(45deg, #bcffe0, #c6c4ff);
}

.frame:nth-child(4) {
  background: #baffe2;
  opacity: 0.9;
}

.frame:nth-child(5) {
  background: linear-gradient(45deg, #bcffe0, #c6c4ff);
}

.frame:nth-child(6) {
  background: #c1c1ff;
}

.frame:nth-child(17) {
  background: #c1c1ff;
}

.frame:nth-child(8) {
  background: linear-gradient(45deg, #bcffe0, #c6c4ff);
}

.frame:nth-child(10) {
  background: #baffe2;
  opacity: 0.9;
}

.frame:nth-child(12) {
  background: linear-gradient(45deg, #bcffe0, #c6c4ff);
}

.frame:nth-child(13) {
  background: #c1c1ff;
}

.frame p:first-child {
  font-size: 2vw; /* 32px -> 2.5vw */
  font-weight: 400;
  display: flex;
  align-items: center;
}

.frame p:last-child {
  font-size: 1.95vw; /* 25px -> 1.953125vw */
  font-weight: 300;
}

.frame p > span > img {
  padding-right: 1.17vw; /* 15px -> 1.171875vw */
  vertical-align: middle;
}

/* 우측 콘텐츠 스타일 유지 */
.about-second ul .right {
  width: 39.0625vw; /* 500px -> 39.0625vw */
  margin-left: 10.9375vw; /* 140px -> 10.9375vw */
  margin-top: 10.9375vw; /* 140px -> 10.9375vw */
}

.about-second ul .right > h3 {
  background: linear-gradient(90deg, #bcffe0, #c6c4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 300;

  padding-left: 0.390625vw; /* 5px -> 0.390625vw */
  font-size: 1.25vw; /* 18px -> 1.40625vw */
}

.about-second ul .right > h2 {
  font-weight: 700;
  font-size: 3vw; /* 50px -> 3.90625vw */
  line-height: 4.2vw; /* 60px -> 4.6875vw */
  letter-spacing: -0.078125vw; /* -1px -> -0.078125vw */
  padding: 1.40625vw 0 1.953125vw 0; /* 18px 0 25px 0 -> 1.40625vw 0 1.953125vw 0 */
}

.about-second ul .right > h2 span {
  font-weight: 200;
}

.about-second ul .right > p {
  font-size: 1.2vw; /* 20px -> 1.5625vw */
  letter-spacing: -0.03125vw; /* -0.4px -> -0.03125vw */
  font-weight: 200;
  line-height: 150%;
  color: #d9d9d9;
  opacity: 0.8;
}

@media screen and (max-width: 1280px) {
  .about-second {
    width: 1080px;
    min-width: 1080px;
  }

  .about-second .container {
    width: 1080px;
    min-width: 1080px;
    overflow: hidden;
  }

  .about-second ul {
    width: 1080px;
  }

  .about-second ul .left {
    padding-left: 90px; /* 10vw of 1280px */
  }

  .about-second ul .left .banner {
    width: 472px; /* 36.875vw of 1280px */
    height: 518px; /* 40.45vw of 1280px */
    border-radius: 45px;
  }

  /* 프레임 관련 스타일 */
  @keyframes spin {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-780px); /* calc(-7vw * 2) of 1280px */
    }
  }

  .frames-container.completed {
    transform: translateY(-780px);
  }

  .frame {
    width: 386px; /* 30.15625vw of 1280px */
    height: 84px; /* 6.59375vw of 1280px */
    line-height: 84px;
    margin-bottom: 15px;
    border-radius: 30px;
    padding: 31px 36px;
  }

  /* 청담/압구정 프레임 스타일 */
  .frames-container.completed .frame:nth-child(11) {
    border-radius: 29px;
    border: 2px solid #eda80f;
    box-shadow: 0px 0px 36px 0px rgba(255, 75, 18, 0.7);
  }

  .frame p:first-child {
    font-size: 26px; /* 2.0vw of 1280px */
  }

  .frame p:last-child {
    font-size: 25px; /* 1.95vw of 1280px */
  }

  .frame p > span > img {
    padding-right: 15px;
  }

  /* 우측 콘텐츠 */
  .about-second ul .right {
    width: 500px;
    margin-left: 120px;
    margin-top: 140px;
  }

  .about-second ul .right > h3 {
    padding-left: 5px;
    font-size: 18px;
  }

  .about-second ul .right > h2 {
    font-size: 44px;
    line-height: 60px;
    letter-spacing: -1px;
    padding: 18px 0 25px 0;
  }

  .about-second ul .right > p {
    font-size: 20px;
    letter-spacing: -0.4px;
  }
}

@media screen and (max-width: 480px) {
  .about-second {
    width: 100%;
    min-width: auto;
    margin-top: 0; /* 마진 제거 */
    height: 100vh; /* 뷰포트 높이로 고정 */
    display: flex;
    align-items: center;
    overflow: hidden; /* 오버플로우 방지 */
  }

  .about-second .container {
    width: 100%;
    min-width: auto;
    height: auto; /* 100vh에서 auto로 변경 */
    padding: 0 2.17vw;
    overflow: visible;
  }

  .about-second ul {
    width: 100%;
    flex-direction: column;
    position: relative;
    top: 0; /* 16.67vw에서 0으로 변경 */
    transform: none;
    align-items: center; /* 수평 중앙 정렬 */
  }

  .about-second ul .top {
    width: 100%;
    margin: 0;
    margin-bottom: 8.33vw;
  }

  .about-second ul .top p {
    color: #9d9d9d;
    text-align: center;
    font-family: Pretendard;
    font-size: 4.3vw; /* 13.204px -> (13.204/480)*100 = 2.75vw */
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.055vw; /* -0.264px -> (-0.264/480)*100 = -0.055vw */
  }

  .about-second ul .top h2 {
    padding-top: 5vw;
    color: #fff;
    text-align: center;
    font-family: Pretendard;
    font-size: 7.5vw; /* 28px -> (28/480)*100 = 5.833vw */
    font-weight: 700;
    line-height: 140%;
    letter-spacing: -0.233vw; /* -1.12px -> (-1.12/480)*100 = -0.233vw */
  }

  .about-second ul .right {
    display: none;
  }

  /* 배너 영역 */
  .about-second ul .left {
    width: 100%;
    padding: 0;
  }

  .about-second ul .left .banner {
    width: 100%;
    height: 80vw; /* 500px */
    border-radius: 6.25vw; /* 30px */
    margin-top: 14vw;
  }

  .frame {
    width: 80%; /* 90%에서 85%로 조정 */
    height: 15vw;
    padding: 0vw 4.17vw; /* 우측 패딩 살짝 증가 */
    border-radius: 3.04vw;
    border-width: 0.19vw 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between; /* 내부 요소 간격 최적화 */
    margin-bottom: 2.08vw;
  }

  .frames-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center; /* 프레임들 중앙 정렬 */
  }

  /* 프레임 내부 텍스트 영역 조정 */
  .frame p:first-child {
    font-size: 4.7vw;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 2.08vw; /* 아이콘과 텍스트 사이 간격 */
  }

  .frame p:last-child {
    font-size: 3.33vw;
    margin-left: auto; /* 우측 정렬 */
    white-space: nowrap; /* 텍스트 줄바꿈 방지 */
  }

  .frame p > span > img {
    width: 6.17vw;
    height: 6.17vw;
    padding-right: 0; /* 기존 패딩 제거 */
  }

  /* 애니메이션 조정 */
  @keyframes spin {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(calc(-12.58vw * 11)); /* -70px * 2 */
    }
  }

  .frames-container.completed {
    transform: translateY(calc(-12.58vw * 11));
  }

  /* 청담/압구정 프레임 스타일 */
  .frames-container.completed .frame:nth-child(11) {
    border-radius: 3.13vw; /* 15px */
    border: 2px solid #eda80f;
    box-shadow: 0px 0px 36px 0px rgba(255, 75, 18, 0.7);
    opacity: 1 !important; /* 우선순위 부여 */
  }

  .frames-container.completed .frame:not(:nth-child(11)) {
    opacity: 0.3;
  }
}

/* 480px 초과 시에는 숨김 */
@media screen and (min-width: 481px) {
  .top {
    display: none;
  }
}
