.about-third {
  max-width: 1920px; /* 1280px/1920px * 100 */
  margin: 0 auto;
}

.about-third .wrapper {
  height: 100vh;
  margin-top: 5vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-third ul {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.about-third ul .left {
  position: relative;
  width: 33.33vw; /* 640px/1920px * 100 */
  top: -4vw;
  transform: translateX(-5vw); /* 왼쪽으로 밀기 */
}

/* common */
.about-third ul .left h3 {
  padding-left: 0.26vw; /* 5px/1920px * 100 */
  font-size: 1.25vw; /* 18px/1920px * 100 */
  background: linear-gradient(90deg, #bcffe0, #c6c4ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 300;
  margin-bottom: 0.8vw;
}

.about-third ul .left h2 {
  font-size: 3vw; /* 50px/1920px * 100 */
  font-weight: 700;
  line-height: 4.2vw; /* 60px/1920px * 100 */
  letter-spacing: -0.052vw; /* -1px/1920px * 100 */
  padding: 0.9375vw 0 1.302vw 0; /* 18px 0 25px 0 변환 */
}

.about-third ul .left p {
  font-size: 1.2vw; /* 20px/1920px * 100 */
  font-weight: 200;
  line-height: 150%;
  letter-spacing: -0.021vw; /* -0.4px/1920px * 100 */
  color: #d9d9d9;
  opacity: 0.8;
}

.about-third ul .left span {
  font-weight: 200;
}

.about-third ul .right {
  position: relative;
  width: 33.33vw; /* 640px/1920px * 100 */
}

.about-third ul .right .file {
  position: absolute;
  left: 8vw;
  bottom: -4vw;
}

.about-third ul .right .video {
  position: relative;
  width: 14.427vw; /* 277px/1920px * 100 */
  height: 25.885vw; /* 497px/1920px * 100 */
  border-radius: 2.083vw; /* 40px/1920px * 100 */
  overflow: hidden;
}

.about-third ul .right .video img {
  position: absolute;
  top: 1vw;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 2.083vw; /* 40px/1920px * 100 */
}

.right span > video {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.right span > img {
  width: 100%;
  height: 100%;
}

.about-third ul .right .videoImage {
  position: absolute;
}

.about-third ul .right .svg02 {
  position: absolute;
  top: 17vw;
  left: 4%;
  z-index: 99;
  width: 12.5vw; /* 240px/1920 * 100 = 12.5vw */
  height: 2.45vw; /* 47px/1920 * 100 = 2.45vw */
}

.about-third ul .right .svg03 {
  position: absolute;
  top: -13vw;
  left: 0%;
  z-index: -5;
  width: 11.41vw; /* 219px/1920 * 100 = 11.41vw */
  height: 13.28vw; /* 255px/1920 * 100 = 13.28vw */
}

.about-third ul .right .svg04 {
  position: absolute;
  bottom: -1vw;
  right: -2%;
  z-index: -4;
  width: 14.22vw; /* 273px/1920 * 100 = 14.22vw */
  height: 19.06vw; /* 366px/1920 * 100 = 19.06vw */
}

/* 1280px 이하 적응형 */
@media screen and (max-width: 1280px) {
  .about-third {
    width: 1080px;
    min-width: 1080px;
    margin: 0 auto;
    position: relative; /* 추가 */
  }

  .about-third .wrapper {
    width: 1080px;
    min-width: 1080px;
    overflow: hidden;
    margin-top: 40px;
  }

  .about-third ul {
    width: 1080px;
    display: flex;
    justify-content: center;
  }

  .about-third ul .left {
    width: 427px;
    top: -51px;
    transform: none;
  }

  .about-third ul .left h3 {
    padding-left: 3px;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .about-third ul .left h2 {
    font-size: 44px;
    line-height: 54px;
    letter-spacing: -1px;
    padding: 12px 0 17px 0;
  }

  .about-third ul .left p {
    font-size: 17px;
    letter-spacing: -0.3px;
  }

  .about-third ul .right {
    width: 427px; /* 33.33vw of 1280px */
  }

  .about-third ul .right .file {
    left: 102px;
    bottom: -51px;
  }

  .about-third ul .right .video {
    width: 185px;
    height: 331px;
    border-radius: 27px;
  }

  .about-third ul .right .video img {
    top: 13px;
    border-radius: 27px;
  }

  /* SVG 요소들 */
  .about-third ul .right .svg02 {
    top: 218px;
    left: 4%;
    width: 160px;
    height: 31px;
  }

  .about-third ul .right .svg03 {
    top: -166px;
    left: 0%;
    width: 146px;
    height: 170px;
  }

  .about-third ul .right .svg04 {
    bottom: -13px;
    right: -2%;
    width: 182px;
    height: 244px;
  }
}

@media screen and (max-width: 480px) {
  .about-third {
    width: 100%;
    min-width: auto;
    margin-top: 0; /* 마진 제거 */
    height: 100vh; /* 뷰포트 높이로 고정 */
    display: flex;
    align-items: center;
    overflow: hidden; /* 오버플로우 방지 */
  }

  .about-third .wrapper {
    width: 100%;
    min-width: auto;
    height: auto; /* 100vh에서 auto로 변경 */
  }

  .about-third ul {
    width: 100%;
    flex-direction: column;
    position: relative;
    top: 0; /* 15vw에서 0으로 변경 */
    transform: none;
  }

  .about-third ul .left {
    width: 100%;
    top: 0;
    transform: none;
    text-align: left; /* center에서 left로 변경 */
    margin-bottom: 16.5vw;
  }

  .about-third ul .left h3 {
    padding-left: 0;
    font-size: 4.7vw;
    margin-bottom: 2.08vw;
    text-align: left; /* center에서 left로 변경 */
  }

  .about-third ul .left h2 {
    font-size: 7.5vw;
    line-height: 9.33vw;
    letter-spacing: -0.21vw;
    padding: 2.08vw 0;
    text-align: left; /* center에서 left로 변경 */
  }

  .about-third ul .left p {
    font-size: 4.3vw;
    line-height: 140%;
    letter-spacing: -0.1vw;
    text-align: left; /* center에서 left로 변경 */
    color: #9d9d9d;
  }

  /* 비디오 영역 */
  .about-third ul .right {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    height: 104.17vw; /* 500px */
  }

  .about-third ul .right .file {
    position: absolute;
    left: 6vw;
    top: 2vw;
    margin: 0 auto;
  }

  .about-third ul .right .video {
    width: 43.5vw; /* 300px */
    height: 68.5vw; /* 400px */
    border-radius: 4.17vw; /* 20px */
  }

  .about-third ul .right .video img {
    top: 0;
    border-radius: 4.17vw; /* 20px */
  }

  /* SVG 요소들 위치 조정 */
  .about-third ul .right .svg02 {
    top: 77.5vw; /* 300px */
    left: 40%;
    transform: translateX(-50%);
    z-index: 99;
  }

  .about-third ul .right .svg03 {
    top: -10.33vw; /* -40px */
    left: 64%; /* 40px */
    transform: translateX(-50%);
    width: 35.625vw; /* 120px */
    height: 32.916vw; /* 140px */

    z-index: 98;
  }

  .about-third ul .right .svg04 {
    top: 33.33vw; /* -40px */
    left: 75%; /* 40px */
    transform: translateX(-50%);
    width: 35.25vw; /* 120px */
    height: 47.708vw; /* 160px */

    z-index: 97;
  }

  /* 비디오 크기 조정 */
  .right span > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .right span > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
